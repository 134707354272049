import { FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Grid, Skeleton, Box, Typography } from '@mui/material';
import { GhostIcon } from '@fairplay2/ui';
import { ChartProps } from './Chart.types';

const CHART_OPTIONS: Highcharts.Options = {
    chart: {
        zoomType: 'xy',
        panning: { enabled: true },
        panKey: 'shift',
        style: {
            fontFamily: "'Montserrat', sans-serif",
        },
    },
    legend: {
        symbolWidth: 16,
        itemStyle: {
            fontWeight: 'normal',
            fontSize: '12px',
            color: '#00000099',
        },
        align: 'left',
        x: 30,
        y: 20,
    },
    tooltip: {
        valueDecimals: 2,
        valuePrefix: '$',
        shared: true,
    },
    credits: {
        enabled: false,
    },
    title: {
        text: '',
    },
    yAxis: {
        title: {
            text: '',
        },
        gridLineDashStyle: 'LongDash',
    },
    xAxis: {
        type: 'datetime',
        labels: {
            formatter(this: any): any {
                return Highcharts.dateFormat('%Y-%m', this.value);
            },
        },
    },
    plotOptions: {
        series: {
            label: {
                connectorAllowed: false,
            },
            marker: {
                enabled: true,
                radius: 3.5,
            },
        },
    },
};

const Chart: FC<ChartProps> = ({ isEmpty, loading, series, tooltip, emptyStateComponent }) => {
    const options: Highcharts.Options = {
        ...CHART_OPTIONS,
        tooltip: tooltip || CHART_OPTIONS.tooltip,
        series,
    };

    return loading ? (
        <Skeleton variant="rectangular" height={400} />
    ) : isEmpty ? (
        emptyStateComponent ? (
            emptyStateComponent
        ) : (
            <Grid container justifyContent="center" alignItems="center" height={400}>
                <GhostIcon width={50} />
                <Box mt="10px" ml="20px">
                    <Typography variant="body1" color="primary.main" fontWeight={700} mb={1}>
                        Nada por aquí
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary" fontWeight={700}>
                        No hay información suficiente para generar la gráfica.
                        <br />
                        Puedes reportar esta incidencia con el team de Data en Fairplay.
                    </Typography>
                </Box>
            </Grid>
        )
    ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};

export default Chart;
