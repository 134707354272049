import { ChangeEventHandler, useEffect, useState, VFC } from 'react';
import { Box, BoxProps, Collapse, Grid, MenuItem, Skeleton } from '@mui/material';
import { MultilineChart, ViewList } from '@mui/icons-material';
import { Alert, IconBtn, Select, Typography } from '@fairplay2/ui';
import { responseToCamelCase } from 'core/utils';
import { fairplayAPI } from 'core/network';
import { ProjectionSummary, useQuote } from '../../state';
import { PROJECTION_SOURCE_DIC } from '../../constants';
import ProjectionCharts from './ProjectionCharts';
import ProjectionTable from './ProjectionTable';
import Step2Instructions from './Step2Instructions';
import { ConfirmationPromptModal } from 'modules/quotes/components';
import { DubitativeManSvg } from 'assets/SVGs';

const ProjectionStep: VFC<Omit<BoxProps, 'children'>> = (props) => {
    const { company, projectionSummary, latestProjections, formMode, dispatch } = useQuote(),
        [selectedProjection, setSelectedProjection] = useState(
            projectionSummary?.projectionId || '',
        ),
        [displayInfo, setDisplayInfo] = useState(true),
        [errors, setErrors] = useState<{ gmv: string; summary: string; list: string }>({
            gmv: '',
            summary: '',
            list: '',
        }),
        [selectedView, setSelectedView] = useState<'charts' | 'table'>('table'),
        [confirmationPromptIsOpen, setConfirmationPromptIsOpen] = useState(false),
        [selectIsOpen, setSelectIsOpen] = useState(false);

    const onProjectionChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const projectionId = e.target.value;

        setSelectedProjection(projectionId);
        dispatch({ type: 'SET_PROJECTION_SUMMARY', payload: null });
        setErrors({ gmv: '', summary: '', list: '' });

        fairplayAPI
            .get(`/api/v2/calculator-proposal/${projectionId}/company-info`, {
                baseService: 'CALCULATOR',
            })
            .then((res: any) => {
                dispatch({
                    type: 'SET_PROJECTION_SUMMARY',
                    payload: {
                        ...responseToCamelCase(res.data.body.results[0] as Record<string, any>),
                        projectionId,
                    } as ProjectionSummary,
                });
            })
            .catch((e) => {
                setErrors((prev) => ({
                    ...prev,
                    summary:
                        e.response?.data?.errors[0]?.error ||
                        'Se ha producido un error. Intente más tarde',
                }));
            });

        fairplayAPI
            .get(`/api/v2/calculator-proposal/${projectionId}/monthly-projections`, {
                baseService: 'CALCULATOR',
            })
            .then((res: any) => {
                dispatch({
                    type: 'SET_PROJECTION_DATAPOINTS',
                    payload: { gmv: res.data.body.results[0].projections },
                });
            })
            .catch((e) => {
                setErrors((prev) => ({
                    ...prev,
                    gmv:
                        e.response?.data?.errors[0]?.error ||
                        'Se ha producido un error. Intente más tarde',
                }));
                dispatch({
                    type: 'SET_PROJECTION_DATAPOINTS',
                    payload: { gmv: [] },
                });
            });
    };

    const onOpenSelect = () => {
        if (formMode === 'calculated') {
            setConfirmationPromptIsOpen(true);
        } else setSelectIsOpen(true);
    };

    const onConfirmProjectionChange = () => {
        dispatch({ type: 'SET_INPUT_DATA', payload: null });
        dispatch({ type: 'SET_CALCULATION_RESULT', payload: null });
        dispatch({
            type: 'SET_PAYMENT',
            payload: { estimatedSignatureDate: undefined, paymentModel: 'revenue-based' },
        });
        dispatch({
            type: 'SET_MODE',
            payload: 'new',
        });
        setSelectIsOpen(true);
    };

    useEffect(() => {
        if (!company || !!latestProjections) return;

        setErrors({ gmv: '', summary: '', list: '' });
        fairplayAPI
            .get(`/api/v2/calculator-proposal/${company.companyId}/projections`, {
                baseService: 'CALCULATOR',
            })
            .then((res: any) => {
                dispatch({
                    type: 'SET_LATEST_PROJECTIONS',
                    payload: res.data.body.results.map(responseToCamelCase),
                });
            })
            .catch((e) => {
                setErrors((prev) => ({
                    ...prev,
                    list:
                        e.response?.data?.errors[0]?.error ||
                        'Se ha producido un error. Intente más tarde',
                }));
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box width="100%" {...props}>
            <ConfirmationPromptModal
                open={confirmationPromptIsOpen}
                onClose={() => setConfirmationPromptIsOpen(false)}
                title="¿Estas seguro? Se perderá la información"
                img={<DubitativeManSvg width="166px" height="180px" />}
                cancelTxt="Cancelar"
                confirmTxt="Sí, estoy seguro"
                onConfirm={onConfirmProjectionChange}
                sx={{ '.MuiDialogTitle-root': { textAlign: 'left' } }}
            >
                <Grid container textAlign="left">
                    <Grid item>
                        <Typography variant="subtitle2">
                            Si cambias la proyección los datos que has ingresado o seleccionado, se
                            perderán y deberás comenzar de nuevo.
                        </Typography>
                    </Grid>
                </Grid>
            </ConfirmationPromptModal>
            {!latestProjections && errors.list === '' ? (
                <Skeleton width={300} height={52} />
            ) : (
                <Select
                    label="Proyección a utilizar"
                    value={selectedProjection}
                    onChange={onProjectionChange}
                    sx={{ width: { sm: 300 } }}
                    SelectProps={{
                        open: selectIsOpen,
                        onOpen: onOpenSelect,
                        onClose: () => setSelectIsOpen(false),
                    }}
                    disabled={!!errors.list}
                >
                    <MenuItem value="" selected disabled>
                        Selecciona la proyección
                    </MenuItem>
                    {latestProjections?.map((item) => (
                        <MenuItem
                            value={item.projectionId}
                            key={item.projectionId}
                            sx={{ textTransform: 'uppercase' }}
                        >
                            {item.date.replaceAll('-', '/')}
                            {item.sourceId !== 'projections'
                                ? `/${
                                      PROJECTION_SOURCE_DIC[
                                          item.sourceId as keyof typeof PROJECTION_SOURCE_DIC
                                      ]
                                  }`
                                : ''}
                        </MenuItem>
                    ))}
                </Select>
            )}

            <Collapse in={!!errors.list}>
                <Alert
                    severity="error"
                    sx={{ maxWidth: 500, my: 2 }}
                    aria-label="Error en listado de proyecciones"
                >
                    {errors.list}
                </Alert>
            </Collapse>

            {!selectedProjection && (
                <Step2Instructions justifyContent="center" mt="30px" mx="auto" />
            )}

            <Collapse in={!!errors.summary}>
                <Alert
                    severity="error"
                    sx={{ maxWidth: 500, my: 2 }}
                    aria-label="Error en detalle de proyección"
                >
                    {errors.summary}
                </Alert>
            </Collapse>

            <Box width="100%" maxWidth={802} display="flex" flexWrap="wrap">
                {!!selectedProjection && (
                    <>
                        {!errors.summary && (
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                                width="100%"
                                mt={2.5}
                            >
                                {projectionSummary ? (
                                    <>
                                        <Typography
                                            variant="subtitle1"
                                            color="primary.dark"
                                            fontWeight={700}
                                            mr="auto"
                                            minWidth="10em"
                                        >
                                            Regular prophet
                                        </Typography>
                                        <Collapse in={!!displayInfo}>
                                            <Alert severity="info" sx={{ mr: 3, ml: 2 }}>
                                                Cambia la vista para ver las gráficas
                                            </Alert>
                                        </Collapse>
                                    </>
                                ) : (
                                    <Skeleton height={30} sx={{ flexGrow: 1, mr: 4 }} />
                                )}
                                <IconBtn
                                    disabled={!projectionSummary}
                                    onClick={() => {
                                        setSelectedView('table');
                                        setDisplayInfo(false);
                                    }}
                                    sx={{
                                        color:
                                            selectedView === 'table' ? 'secondary.main' : '#cccccc',
                                        mr: 1,
                                        '&:hover': { color: 'secondary' },
                                    }}
                                >
                                    <ViewList />
                                </IconBtn>
                                <IconBtn
                                    disabled={!projectionSummary}
                                    onClick={() => {
                                        setSelectedView('charts');
                                        setDisplayInfo(false);
                                    }}
                                    sx={{
                                        color:
                                            selectedView === 'charts'
                                                ? 'secondary.main'
                                                : '#cccccc',
                                        '&:hover': { color: 'secondary.main' },
                                    }}
                                >
                                    <MultilineChart />
                                </IconBtn>
                            </Box>
                        )}
                        <Collapse in={!!errors.gmv}>
                            <Alert
                                severity="error"
                                sx={{ maxWidth: 500, my: 2 }}
                                aria-label="Error en datos de GMV"
                            >
                                {errors.gmv}
                            </Alert>
                        </Collapse>

                        {selectedView === 'charts' ? (
                            <ProjectionCharts projectionId={selectedProjection} />
                        ) : (
                            <ProjectionTable projectionId={selectedProjection} />
                        )}
                    </>
                )}
            </Box>
        </Box>
    );
};

export default ProjectionStep;
