import { ProjectionItem } from './interfaces';

export const CHART_COLORS: Record<string, string> = {
    gmv: '#9689F2',
    trend: '#B772C4',
    seasonality: '#18ADE3',
};

export const CHART_TITLES = {
    trend: {
        title: 'Tendencia',
        legend: 'Es la tendencia mensual de ventas',
    },
    seasonality: {
        title: 'Estacionalidad',
        legend: 'Esta es la distribución por mes',
    },
};

export const CHART_BASE_SERIES = {
    type: 'line' as 'line',
    lineWidth: 2,
    name: 'Regular Prophet',
    showInLegend: false,
};

export const CHART_TOOLTIPS = {
    seasonality: {
        valueDecimals: 2,
        pointFormat:
            '<span style="color:{series.color}">% de Ventas</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true,
    },
    trend: {
        valueDecimals: 2,
        valuePrefix: '$',
        pointFormat:
            '<span style="color:{series.color}">{series.name} - Ventas esperadas</span>: <b>{point.y}</b><br/>',
        shared: true,
    },
};

export const PROJECTION_SOURCE_DIC: Record<ProjectionItem['sourceId'], string> = {
    sat: 'SAT',
    django: 'Conectores',
    projections: 'Proyecciones',
};
