import { styled } from '@mui/material';
import { DefaultInput } from '@fairplay2/ui';
import { noDataStyles } from './CustomDefaultInput.styles';
import { CustomDefaultInputProps } from './CustomDefaultInput.types';

const CustomDefaultInput = styled(({ value, noData, ...props }: CustomDefaultInputProps) => (
    <DefaultInput
        labelVariant="external"
        inputAlignment="right"
        value={noData ? '' : value}
        {...props}
    />
))(noDataStyles);

export default CustomDefaultInput;
