import { VFC } from 'react';
import { Box, Divider } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { CompanyDataProps } from '../interfaces';
import { toCurrency, toPercentage } from 'core/utils';

const CompanyData: VFC<CompanyDataProps> = ({ company, projection }) => (
    <>
        <Typography variant="subtitle2" color="primary.dark" fontWeight={700} lineHeight={1.375}>
            {company.companyBusinessName}
        </Typography>
        <Typography variant="subtitle2" color="primary.main" lineHeight={1.375}>
            {company.companyRfc}
        </Typography>
        {!!projection && (
            <>
                <Divider sx={{ mt: 3, mb: 2 }} />

                <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography variant="body1" color="text.primary">
                        Crecimiento
                    </Typography>
                    <Typography variant="body1" color="primary.main" fontWeight={700}>
                        {toPercentage(projection.growth)}
                    </Typography>
                </Box>
                {!!projection.size && (
                    <Box display="flex" justifyContent="space-between" my={1}>
                        <Typography variant="body1" color="text.primary">
                            Size
                        </Typography>
                        <Typography variant="body1" color="primary.main" fontWeight={700}>
                            {projection.size}
                        </Typography>
                    </Box>
                )}

                <Divider sx={{ mt: 2, mb: 3 }} />

                <Typography variant="subtitle2" color="primary.dark" fontWeight={700}>
                    Ventas mensuales
                </Typography>
                <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography variant="body1" color="text.primary">
                        Reales
                    </Typography>
                    <Typography variant="body1" color="primary.main" fontWeight={700}>
                        $ {toCurrency(projection.realMonthlySales)}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography variant="body1" color="text.primary">
                        Proyectadas
                    </Typography>
                    <Typography variant="body1" color="primary.main" fontWeight={700}>
                        $ {toCurrency(projection.projectedMonthlySales)}
                    </Typography>
                </Box>

                <Divider sx={{ my: 3 }} />

                <Typography variant="subtitle2" color="primary.dark" fontWeight={700}>
                    Ventas diarias
                </Typography>
                <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography variant="body1" color="text.primary">
                        Reales
                    </Typography>
                    <Typography variant="body1" color="primary.main" fontWeight={700}>
                        $ {toCurrency(projection.realDailySales)}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography variant="body1" color="text.primary">
                        Proyectadas
                    </Typography>
                    <Typography variant="body1" color="primary.main" fontWeight={700}>
                        $ {toCurrency(projection.projectedDailySales)}
                    </Typography>
                </Box>

                <Divider sx={{ my: 3 }} />

                <Typography variant="subtitle2" color="primary.dark" fontWeight={700}>
                    Valores sugeridos
                </Typography>
                <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography variant="body1" color="text.primary">
                        Factor de reembolso
                    </Typography>
                    <Typography variant="body1" color="primary.main" fontWeight={700}>
                        {toPercentage(projection.suggestedTakeRate, {
                            removeTrailingZeros: true,
                        })}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" my={1}>
                    <Typography variant="body1" color="text.primary">
                        Pago diario min.
                    </Typography>
                    <Typography variant="body1" color="primary.main" fontWeight={700}>
                        $ {toCurrency(projection.suggestedLowerBand)}
                    </Typography>
                </Box>
            </>
        )}
    </>
);

export default CompanyData;
