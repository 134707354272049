import { DefaultInput } from '@fairplay2/ui';
import { CurrencyInput } from '@fairplay2/ui-x-currencies';
import { useFormContext } from 'core/state';
import { ChangeEvent, forwardRef, useEffect, FC } from 'react';
import { FormItemProps } from './FormItem.types';

const FormItem: FC<FormItemProps> = forwardRef(
    ({ name, validators, inputType = 'text', ...props }, ref) => {
        const {
            dispatch: formDispatch,
            formData: { data: formData, showErrors: showFormErrors, errors: formErrors },
        } = useFormContext();

        const onValueChange = (ev: ChangeEvent<HTMLInputElement>) => {
            const value = typeof ev === 'number' ? ev : ev ? ev.target.value : undefined;

            formDispatch({ type: 'SET_FORM_VALUE', payload: { name, value } });
            const errors = validators
                ?.map((validator) => validator(value))
                ?.filter((error) => !!error);
            formDispatch({ type: 'SET_ERROR', payload: { name, error: errors?.[0] || '' } });
        };

        useEffect(() => {
            const value = formData[name] || undefined,
                errors = validators?.map((validator) => validator(value)).filter((error) => error);

            formDispatch({ type: 'SET_ERROR', payload: { name, error: errors?.[0] || '' } });
            formDispatch({ type: 'SET_FORM_VALUE', payload: { name, value } });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return inputType === 'text' ? (
            <DefaultInput
                ref={ref}
                labelVariant="external"
                inputAlignment="right"
                onChange={onValueChange}
                value={formData[name]}
                error={showFormErrors ? formErrors[name] : undefined}
                {...props}
            />
        ) : (
            <CurrencyInput
                ref={ref}
                labelVariant="external"
                inputAlignment="right"
                startAdornment={null}
                allowNegative={false}
                onValueChange={({ floatValue }) => onValueChange(floatValue as any)}
                value={formData[name]}
                error={showFormErrors ? formErrors[name] : undefined}
                InputProps={{
                    // TODO: remove when the issue ignoreDisabledStyles issue #25 in library is fixed
                    autoComplete: 'off',
                }}
                {...props}
            />
        );
    },
);

export default FormItem;
