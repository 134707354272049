import { FC } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { NotFoundLayout } from 'layouts';
import { LogIn } from 'modules/auth';
import { NewQuote } from 'modules/quotes';
import { AppPage, PrivatePage } from './components';

const Router: FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LogIn />} />
                {/* TODO: Evaluate if the project will use nested routers */}
                <Route
                    path="app/*"
                    element={
                        <Routes>
                            <Route
                                path="calculadora"
                                element={<AppPage children={<NewQuote />} />}
                            />
                            <Route
                                path="*"
                                element={<PrivatePage children={<NotFoundLayout />} />}
                            />
                        </Routes>
                    }
                />
                <Route path="*" element={<NotFoundLayout />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Router;
