import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { FairplayAxiosInstance, FairplayRequestConfig } from './interfaces';

const fairplayAPI = axios.create() as FairplayAxiosInstance;

// Different instance to avoid appending other interceptors
//  and handle redirection to login page
const refreshAPI = axios.create();

fairplayAPI.defaults.headers.common.Accept = 'application/json';
fairplayAPI.defaults.headers.post['Content-Type'] = 'application/json';

const getAccessToken = () => sessionStorage.getItem('FPstorage-Token');

const setAccessToken = (token: string) => sessionStorage.setItem('FPstorage-Token', token);

const getRefreshToken = () => sessionStorage.getItem('FPstorage-Refresh');

const setRefreshToken = (token: string) => sessionStorage.setItem('FPstorage-Refresh', token);

const refreshAuthLogic = (failedRequest: any) =>
    refreshAPI
        .post(
            `${process.env.REACT_APP_API_URL}/v1/users/token/refresh`,
            {
                refresh: getRefreshToken(),
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            },
        )
        .then((tokenRefreshResponse: any) => {
            const token = tokenRefreshResponse.data.access;
            setAccessToken(token);
            failedRequest.response.config.headers.Authorization = `Bearer ${token}`;

            return Promise.resolve();
        })
        .catch((error) => Promise.reject(error));

const onFailedRefresh = () => {
    endSession(true);
    return Promise.resolve();
};

createAuthRefreshInterceptor(fairplayAPI as FairplayAxiosInstance, refreshAuthLogic, {
    shouldRefresh(error) {
        // Ignore requests that directly specified no authorization
        //  and those that provide a custom token, as there is no guarantee
        //  that said token can or should be refreshed
        return (
            error.response?.status === 401 &&
            (error.config as FairplayRequestConfig).authorization === true
        );
    },
});

createAuthRefreshInterceptor(refreshAPI, onFailedRefresh, {
    statusCodes: [401, 422, 403],
});

fairplayAPI.interceptors.request.use(
    ({ authorization = true, baseService, ...config }: FairplayRequestConfig) => {
        if (!config.headers) config.headers = {};

        let newBaseURL = config.baseURL;

        if (!newBaseURL) {
            switch (baseService) {
                case 'CALCULATOR':
                    newBaseURL = process.env.REACT_APP_CALC_API_URL;
                    break;
                default:
                    newBaseURL = process.env.REACT_APP_API_URL;
                    break;
            }
        }

        if (authorization && !config.headers.Authorization)
            config.headers['Authorization'] =
                typeof authorization === 'string' ? authorization : `Bearer ${getAccessToken()}`;

        return { ...config, baseURL: newBaseURL, authorization };
    },
);

fairplayAPI.interceptors.response.use(undefined, (error) => {
    if (error.response?.status === 429) endSession(true);
    // TODO: Implement enhanced error handler
    return Promise.reject(error);
});

export const setSession = ({ access, refresh }: { access: string; refresh: string }) => {
    setAccessToken(access);
    setRefreshToken(refresh);
};

export const hasActiveSession = () => {
    const accessToken = getAccessToken();
    return accessToken && accessToken !== 'null' && accessToken !== 'undefined';
};

export const endSession = (useNativeRedirect = false) => {
    window.localStorage.clear();
    localStorage.clear();
    sessionStorage.clear();
    delete fairplayAPI.defaults.headers.common['Authorization'];
    // TODO: Display a message in login explaining why the session was ended
    if (useNativeRedirect) window.location.assign('/');
};

export default fairplayAPI;
