import { VFC } from 'react';
import { Grid, GridProps } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { ReactComponent as ManFacingChart } from 'assets/img/man_facing_chart.svg';

const Step1Instructions: VFC<Omit<GridProps, 'container' | 'alignItems'>> = (props) => (
    <Grid container alignItems="center" {...props}>
        <Grid item maxWidth={320}>
            <Typography variant="subtitle1" color="primary.dark" fontWeight={700} lineHeight={1.44}>
                Es momento de seleccionar la proyección de ventas.
            </Typography>
            <Typography variant="subtitle1" color="primary.main" lineHeight={1.44} mt={2}>
                Identifica la proyección por la fuente de origen, la fecha y la hora en que se
                realizó
            </Typography>
        </Grid>
        <Grid ml={5}>
            <ManFacingChart width={326} aria-hidden="true" />
        </Grid>
    </Grid>
);

export default Step1Instructions;
