import { useReducer, FC, useEffect } from 'react';
import { createContextWithHook } from 'core/hooks';
import { useSession } from 'core/state';
import { noop } from 'core/utils';
import { QuoteContextType } from './interfaces';
import { INITIAL_STATE, quoteReducer, restoreSavedState } from './QuoteReducer';

const [QuoteContext, useQuote] = createContextWithHook<QuoteContextType>(
    {
        ...INITIAL_STATE,
        dispatch: noop,
    },
    'useQuote must be used within a QuoteContext',
);

export const QuoteProvider: FC = ({ children }) => {
    const { user } = useSession(),
        [state, dispatch] = useReducer(quoteReducer, INITIAL_STATE, restoreSavedState);

    useEffect(() => {
        if (!user) dispatch({ type: 'CLEAR_QUOTE' });
    }, [user]);

    return (
        <QuoteContext.Provider
            value={{
                ...state,
                dispatch,
            }}
        >
            {children}
        </QuoteContext.Provider>
    );
};

export { QuoteContext, useQuote };

export default QuoteProvider;
