import { FC } from 'react';
import { AppLayout } from 'layouts';
import PrivatePage from './PrivatePage';

const AppPage: FC = ({ children }) => (
    <PrivatePage>
        <AppLayout>{children}</AppLayout>
    </PrivatePage>
);

export default AppPage;
