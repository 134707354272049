export const capitalize = (str: string): string =>
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

/**
 * Takes in a string of lowercase words separated
 * by underscore (Python naming conv. for variables)
 * and transform it in CamelCase
 */
export const snakeToCamel = (str: string): string =>
    str
        .split('_')
        .map((word, i) => (i !== 0 ? capitalize(word) : word))
        .join('');

export const camelToSnake = (str: string): string =>
    str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

/**
 * Takes an object and transforms its keys to camel case equivalents.
 */
export const responseToCamelCase = (response: Record<string, any>) => {
    if (!response) return {};
    const newResponse: Record<string, any> = {};
    for (let key in response) {
        newResponse[snakeToCamel(key)] = response[key];
    }
    return newResponse;
};

/**
 * Takes an object and transforms its keys from camel to snake case equivalents.
 */
export const camelToSnakeObj = (obj: any): { [key: string]: any } => {
    const snakeObj: { [key: string]: any } = {};

    for (const key in obj) {
        snakeObj[camelToSnake(key)] = obj[key];
    }

    return snakeObj;
};

/**
 * Formats a number using commas to separate groups of 3 digits, and
 * fixes decimal scale to 2 digits.
 */
export const toCurrency = (value: number, removeTrailingZeros = false) =>
    (removeTrailingZeros ? `${value}` : value.toFixed(2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const DATE_TIME_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit', // L M M J V S D
    weekday: 'narrow',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
};

// !! It's important to specify 'es-MX' for correctly parsing the datetime
export const CDMXDateTimeFormatter = new Intl.DateTimeFormat('es-MX', {
    ...DATE_TIME_FORMAT_OPTIONS,
    timeZone: 'America/Mexico_City',
});

// !! It's important to specify 'es-MX' for correctly parsing the datetime
export const CDMXDateTimeFormatterLong = new Intl.DateTimeFormat('es-MX', {
    ...DATE_TIME_FORMAT_OPTIONS,
    timeZone: 'America/Mexico_City',
    weekday: 'short',
    month: 'short',
    day: 'numeric',
});

export const getDateParts = (formatter: Intl.DateTimeFormat, date: Date) =>
    formatter.formatToParts(date).reduce((parts, { value, type }) => {
        parts[type] = value;
        return parts;
    }, {} as Record<ReturnType<Intl.DateTimeFormat['formatToParts']>[0]['type'], string>);
interface ToPercentageConfig {
    removeTrailingZeros?: boolean;
    ignoreConversion?: boolean;
}

export const toPercentage = (value: number, options?: ToPercentageConfig) => {
    const readableValue = (options?.ignoreConversion ? value : value * 100).toFixed(2);
    return `${options?.removeTrailingZeros ? `${Number(readableValue)}` : readableValue}%`;
};
