import { useEffect, useReducer, VFC } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { DefaultBtn, TablePagination, TableSkeleton, Typography } from '@fairplay2/ui';
import { getItemsAtPage, paginationReducer, toCurrency } from 'core/utils';
import { ProjectionViewProps } from '../interfaces';
import { useQuote } from '../../state';

const ProjectionTable: VFC<ProjectionViewProps> = ({ projectionId }) => {
    const { projectionDataPoints, projectionSummary, dispatch } = useQuote(),
        [{ page, rowsPerPage, count }, dispatchPagination] = useReducer(paginationReducer, {
            page: 1,
            rowsPerPage: 10,
            count: -1,
        });

    useEffect(() => {
        dispatchPagination({
            type: 'SET_COUNT',
            payload:
                projectionDataPoints.gmv?.length === undefined
                    ? -1
                    : projectionDataPoints.gmv.length,
        });
    }, [projectionDataPoints.gmv?.length]);

    return projectionDataPoints?.gmv ? (
        <>
            <TableContainer sx={{ my: 1 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <Typography
                                color="text.secondary"
                                // @ts-ignore
                                component={TableCell}
                            >
                                Fecha
                            </Typography>
                            <Typography
                                color="text.secondary"
                                // @ts-ignore
                                component={TableCell}
                            >
                                GMV Real
                            </Typography>
                            <Typography
                                color="text.secondary"
                                // @ts-ignore
                                component={TableCell}
                            >
                                GMV Proyectado
                            </Typography>
                            {/* <Typography
                                color="text.secondary"
                                // @ts-ignore
                                component={TableCell}
                            >
                                GMV Total
                            </Typography> */}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ '.MuiTableCell-root': { paddingY: 4 } }}>
                        {getItemsAtPage(projectionDataPoints.gmv, page, rowsPerPage).map(
                            (item, i) => (
                                <TableRow key={i}>
                                    <Typography
                                        color="primary.main"
                                        fontWeight={700}
                                        // @ts-ignore
                                        component={TableCell}
                                    >
                                        {item.date}
                                    </Typography>
                                    <Typography
                                        color="primary.dark"
                                        fontWeight={700}
                                        // @ts-ignore
                                        component={TableCell}
                                    >
                                        $ {toCurrency(item.real_gmv)}
                                    </Typography>
                                    <Typography
                                        color="primary.main"
                                        fontWeight={700}
                                        // @ts-ignore
                                        component={TableCell}
                                    >
                                        $ {toCurrency(item.projected_gmv)}
                                    </Typography>
                                    {/* <Typography
                                    color="primary.dark"
                                    fontWeight={700}
                                    // @ts-ignore
                                    component={TableCell}
                                >
                                    $ {toCurrency(item.totalGMV)}
                                </Typography> */}
                                </TableRow>
                            ),
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Typography color="text.secondary" variant="body2" mr={1}>
                    *Todos los montos están reflejados en pesos mexicanos.
                </Typography>
                <TablePagination
                    count={count}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={(e, page) =>
                        dispatchPagination({ type: 'SET_PAGE', payload: page })
                    }
                    onRowsPerPageChange={(e) =>
                        dispatchPagination({
                            type: 'SET_ROWS_PER_PAGE',
                            payload: Number(e.target.value),
                        })
                    }
                />
            </Box>
            <DefaultBtn
                size="small"
                sx={{ ml: 'auto', mt: 2 }}
                disabled={!projectionSummary}
                onClick={() => dispatch({ type: 'SET_USER_STEP', payload: 1 })}
            >
                Confirmar Proyección
            </DefaultBtn>
        </>
    ) : (
        <table style={{ width: '100%' }}>
            <tbody>
                <TableSkeleton columns={3} rows={4} />
            </tbody>
        </table>
    );
};

export default ProjectionTable;
