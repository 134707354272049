import { FC, useReducer, useRef } from 'react';
import { fairplayAPI, setSession, endSession } from 'core/network';
import { noop } from 'core/utils';
import { createContextWithHook } from 'core/hooks';
import sessionReducer, { INITIAL_STATE, restoreSavedState } from './sessionReducer';
import { SessionContextType, LoginData, User } from './interfaces';

const [SessionContext, useSession] = createContextWithHook<SessionContextType>(
    {
        ...INITIAL_STATE,
        login: () => Promise.reject<User>(),
        logout: noop,
        fetchUser: () => Promise.reject<User>(),
        dispatch: noop,
    },
    'useSession must be used within a SessionContext',
);

const SessionProvider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(sessionReducer, INITIAL_STATE, restoreSavedState);

    const { current: login } = useRef(async (data: LoginData) => {
        dispatch({ type: 'SET_LOADING', payload: null });
        const formData = new FormData();
        for (let [key, value] of Object.entries(data)) {
            formData.append(key, value);
        }
        const res: any = await fairplayAPI.post('/v1/calculator/login', formData, {
            authorization: false,
        });

        if (res.data) {
            const { token, ...user } = res.data.body.results;

            setSession(token);
            dispatch({
                type: 'SET_USER',
                payload: user,
            });

            return user;
        }
    });

    const { current: fetchUser } = useRef(async () => {
        dispatch({ type: 'SET_LOADING', payload: null });
        const res: any = await fairplayAPI.get('/v1/users/profile');

        if (res.data) {
            const user = res.data.body.results;
            dispatch({
                type: 'SET_USER',
                payload: user,
            });
            return user;
        }
    });

    const { current: logout } = useRef(() => {
        endSession(false);
        dispatch({
            type: 'CLEAR_USER',
            payload: null,
        });
    });

    return (
        <SessionContext.Provider
            value={{
                ...state,
                logout,
                login,
                fetchUser,
                dispatch,
            }}
        >
            {children}
        </SessionContext.Provider>
    );
};

export { SessionContext, useSession };

export default SessionProvider;
