import { VFC, Fragment, useEffect, useState } from 'react';
import { Alert, Box, Collapse, Grid, Typography } from '@mui/material';
import { DefaultBtn, Paper } from '@fairplay2/ui';
import { Chart } from 'core/components';
import { fairplayAPI } from 'core/network';
import { useQuote } from '../../state';
import { CHART_COLORS, CHART_TITLES, CHART_BASE_SERIES, CHART_TOOLTIPS } from '../../constants';
import { ProjectionViewProps } from '../interfaces';

const ProjectionCharts: VFC<ProjectionViewProps> = ({ projectionId }) => {
    const { projectionDataPoints, projectionSummary, dispatch } = useQuote(),
        [errors, setErrors] = useState<{ seasonality: string; trend: string }>({
            seasonality: '',
            trend: '',
        });

    useEffect(() => {
        if (projectionId && projectionDataPoints?.trend && projectionDataPoints?.seasonality)
            return;

        setErrors({ seasonality: '', trend: '' });

        fairplayAPI
            .get(`/api/v2/calculator-proposal/${projectionId}/trends`, {
                baseService: 'CALCULATOR',
            })
            .then((res: any) => {
                dispatch({
                    type: 'SET_PROJECTION_DATAPOINTS',
                    payload: { trend: res.data.body.results },
                });
            })
            .catch((e) => {
                setErrors((prev) => ({
                    ...prev,
                    trend:
                        e.response?.data?.errors[0]?.error ||
                        'Se ha producido un error. Intente más tarde',
                }));
                dispatch({
                    type: 'SET_PROJECTION_DATAPOINTS',
                    payload: {
                        trend: [],
                    },
                });
            });

        fairplayAPI
            .get(`/api/v2/calculator-proposal/${projectionId}/seasonality`, {
                baseService: 'CALCULATOR',
            })
            .then((res: any) => {
                dispatch({
                    type: 'SET_PROJECTION_DATAPOINTS',
                    payload: { seasonality: res.data.body.results },
                });
            })
            .catch((e) => {
                setErrors((prev) => ({
                    ...prev,
                    seasonality:
                        e.response?.data?.errors[0]?.error ||
                        'Se ha producido un error. Intente más tarde',
                }));
                dispatch({
                    type: 'SET_PROJECTION_DATAPOINTS',
                    payload: {
                        seasonality: [],
                    },
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectionId]);

    return (
        <Grid container mt={2}>
            {['gmv' as 'gmv', 'trend' as 'trend', 'seasonality' as 'seasonality'].map((key) => (
                <Fragment key={key}>
                    <Grid item xs={12} mb={key === 'seasonality' ? '130px' : undefined}>
                        {key !== 'gmv' && (
                            <Box display="flex" alignItems="baseline" mt={3.2} mb={2.6}>
                                <Typography
                                    variant="subtitle1"
                                    color="primary.dark"
                                    fontWeight={700}
                                >
                                    {CHART_TITLES[key].title}
                                </Typography>
                                <Typography variant="body1" color="text.secondary" ml={3}>
                                    {CHART_TITLES[key].legend}
                                </Typography>
                            </Box>
                        )}
                        <Paper elevation={2} sx={{ padding: 3, bgcolor: 'white' }}>
                            <Chart
                                loading={!projectionDataPoints?.[key]}
                                isEmpty={!projectionDataPoints?.[key]?.length}
                                tooltip={CHART_TOOLTIPS[key as 'seasonality']}
                                series={[
                                    {
                                        ...CHART_BASE_SERIES,
                                        color: CHART_COLORS[key],
                                        data: (projectionDataPoints?.[key] || []).map((item) => [
                                            new Date(item.date).valueOf(),
                                            // @ts-ignore
                                            item[key === 'gmv' ? 'projected_gmv' : key],
                                        ]),
                                    },
                                ]}
                            />
                        </Paper>
                    </Grid>
                    {key === 'gmv' ? (
                        <DefaultBtn
                            size="small"
                            sx={{
                                position: 'sticky',
                                ml: 'auto',
                                mt: 4,
                                zIndex: 100,
                                top: 'calc(90vh - 56px)',
                            }}
                            disabled={!projectionSummary}
                            onClick={() => dispatch({ type: 'SET_USER_STEP', payload: 1 })}
                        >
                            Confirmar Proyección
                        </DefaultBtn>
                    ) : (
                        <Collapse in={!!errors[key]}>
                            <Alert severity="error" sx={{ mt: 3, mb: 1 }}>
                                {errors[key]}
                            </Alert>
                        </Collapse>
                    )}
                </Fragment>
            ))}
        </Grid>
    );
};

export default ProjectionCharts;
