import { FormAction, FormState } from './interfaces';

export const INITIAL_STATE: FormState<any> & { hasErrors: boolean } = {
    data: {},
    errors: {},
    hasErrors: false,
    showErrors: false,
};

const errorsToBoolean = (errors: FormState<any>['errors']) =>
    Object.values(errors).some((error) => !!error);

export const formReducer = <T>(state: FormState<T>, { type, payload }: FormAction<T>) => {
    switch (type) {
        case 'CLEAR_FORM_DATA':
            return INITIAL_STATE;
        case 'SET_FORM_DATA':
            return {
                ...state,
                data: payload,
            } as FormState<T>;
        case 'SET_ERRORS': {
            return {
                ...state,
                errors: payload,
                hasErrors: errorsToBoolean(payload as FormState<T>['errors']),
            } as FormState<T>;
        }
        case 'SET_FORM_VALUE':
            return {
                ...state,
                data: {
                    ...state.data,
                    // @ts-ignore
                    [payload.name]: payload.value,
                },
            } as FormState<T>;
        case 'SET_ERROR':
            const errors = {
                ...state.errors,
                // @ts-ignore
                [payload.name]: payload.error,
            };
            return {
                ...state,
                errors,
                hasErrors: errorsToBoolean(errors),
            } as FormState<T>;
        case 'SHOW_ERRORS':
            return {
                ...state,
                showErrors: payload,
            } as FormState<T>;
        default:
            return state;
    }
};
