import { useState, VFC } from 'react';
import { Grid } from '@mui/material';
import { DefaultBtn } from '@fairplay2/ui';
import { ManSittingWithFilesSvg, ManSittingWithFilesAndCheckSvg } from 'assets/SVGs';
import { CustomDefaultInput } from 'core/components';
import { toCurrency } from 'core/utils/formatting';
import { ConfirmationPromptModal } from 'modules/quotes/components';
import { useQuote } from 'modules/quotes/state';
import { fairplayAPI } from 'core/network';
import { DubitativeManSvg } from 'assets/SVGs';
import { toFormData } from 'core/utils';
import { QuoteSavePayload } from '../../interfaces';

const CalculationResult: VFC = () => {
    const {
            payment,
            formMode,
            userInput,
            calculationResult,
            company,
            projectionSummary,
            dispatch,
        } = useQuote(),
        [saveQuoteModalIsOpen, setSaveQuoteModalIsOpen] = useState(false),
        [newQuoteModalIsOpen, setNewQuoteModalIsOpen] = useState(false),
        [errorModalIsOpen, setErrorModalIsOpen] = useState(false),
        [isLoading, setIsLoading] = useState(false),
        [resComments, setResComments] = useState('');

    const onSaveConfirm = () => {
        setIsLoading(true);

        // To successfully use the 'saveQuote' endpoint, it is necessary to provide repeated values in different keys.
        const saveQuotePayload: QuoteSavePayload = {
            maxProposalAmount: calculationResult?.totalProposal,
            paymentModel: `${payment?.paymentModel}${
                payment?.paymentModel === 'revenue-based' ? '-capped' : ''
            }`,
            estimatedSignatureDate: `${payment?.estimatedSignatureDate?.getFullYear()}-${
                (payment?.estimatedSignatureDate?.getMonth() ?? 0) + 1
            }-${payment?.estimatedSignatureDate?.getDate()}`,
            projectionMethod: calculationResult?.projectionMethod,
            projectionUuid: calculationResult?.projectionUuid,
            disbursementPeriodMonths: calculationResult?.disbursementPeriodMonths,
            gracePeriodDays: calculationResult?.gracePeriodDays,
            projectionDate: calculationResult?.projectionDate
                ? new Date(calculationResult.projectionDate).toISOString()
                : '',
            avgGmv: calculationResult?.avgGmv,
            futureAvgGmv: calculationResult?.futureAvgGmv,
            avgRevenueGrowth: projectionSummary?.growth,
            disbursementFee: calculationResult?.disbursementFee,
            maxDispositionValue: calculationResult?.maxDispositionValue,
            minIrr: calculationResult?.minIrr,
            maxIrr: calculationResult?.maxIrr,
            finGmv: calculationResult?.finGmv || 0,
            peakAmount: calculationResult?.peakAmount,
            minGmv: calculationResult?.minGmv,
            principalAmount: userInput?.principalAmount,
            vat: calculationResult?.vat,
            totalProposal: calculationResult?.totalProposal,
            maxProposalAmountPercentage: calculationResult?.maxProposalAmountPercentage,
            projectedFinancingDays: calculationResult?.projectedFinancingDays,
            takeRate: calculationResult?.takeRate,
            projectedIrr: calculationResult?.projectedIrr,
            maxDailyPayment: calculationResult?.maxDailyPayment,
            minDailyPayment: calculationResult?.minDailyPayment,
            disbursementFeeAmount: calculationResult?.disbursementFeeAmount,
            maxDispositionPercentage: userInput!.maxDispositionPercentage / 100,
            creditLimit: calculationResult?.totalProposal,
            suggestedIrr: calculationResult?.suggestedIrr,
            graph: calculationResult?.graph,
            cashFlow: calculationResult?.cashFlow,
            projectedLowerBandFinancingPayments:
                calculationResult?.projectedLowerBandFinancingPayments,
            projectedUpperBandFinancingPayments:
                calculationResult?.projectedUpperBandFinancingPayments,
            projectedLowerBandFinancingDays: calculationResult?.projectedLowerBandFinancingDays,
            projectedUpperBandFinancingDays: calculationResult?.projectedUpperBandFinancingDays,
            needsCommitteeApproval: !!resComments,
            needsCommitteeApprovalReason: resComments,
            productType: payment?.productType,
            frequency: calculationResult?.frequency,
            frequencyCrontab: calculationResult?.frequencyCrontab,
            calendar: calculationResult?.calendar,
        };

        fairplayAPI
            .post(`/v2/companies/${company?.companyId}/quotes`, toFormData(saveQuotePayload))
            .then(() => {
                setSaveQuoteModalIsOpen(false);
                setNewQuoteModalIsOpen(true);
            })
            .catch(() => {
                setSaveQuoteModalIsOpen(false);
                setErrorModalIsOpen(true);
            })
            .finally(() => setIsLoading(false));
    };

    const onSave = () => {
        setSaveQuoteModalIsOpen(true);
    };

    const onContinueEditing = () => {
        dispatch({
            type: 'SET_MODE',
            payload: 'edit',
        });
    };

    const onNewQuote = () => {
        dispatch({
            type: 'CLEAR_QUOTE',
        });
    };

    return (
        <Grid item container gap={3} direction="column">
            <ConfirmationPromptModal
                isLoading={isLoading}
                open={saveQuoteModalIsOpen}
                onClose={() => {
                    setSaveQuoteModalIsOpen(false);
                }}
                title="¿Estás seguro de que deseas guardar esta propuesta?"
                img={<ManSittingWithFilesSvg width="165px" height="128px" />}
                confirmTxt="Sí, guardar"
                onConfirm={onSaveConfirm}
                closeOnAction={{
                    onConfirm: false,
                    onCancel: true,
                }}
            />

            <ConfirmationPromptModal
                open={newQuoteModalIsOpen}
                onClose={() => {
                    setNewQuoteModalIsOpen(false);
                }}
                title="La propuesta se ha guardado exitosamente."
                img={<ManSittingWithFilesAndCheckSvg width="165px" height="128px" />}
                cancelTxt="Continuar editando"
                confirmTxt="Nueva propuesta"
                onCancel={onContinueEditing}
                onConfirm={onNewQuote}
            >
                ¿Deseas continuar editando esta propuesta o comenzar una nueva?
            </ConfirmationPromptModal>

            <ConfirmationPromptModal
                onlyConfirmBtn
                open={errorModalIsOpen}
                onClose={() => setErrorModalIsOpen(false)}
                title="Oops algo salió mal"
                img={<DubitativeManSvg width="166px" height="180px" />}
                confirmTxt="Entendido"
            >
                No pudimos guardar tu propuesta de financiamiento. Por favor, inténtalo de nuevo.
            </ConfirmationPromptModal>

            <Grid item container gap={3}>
                <Grid item xs={12}>
                    <CustomDefaultInput
                        name="totalProposal"
                        label="Monto total de inversión:"
                        value={
                            calculationResult
                                ? `$ ${toCurrency(calculationResult.totalProposal)}`
                                : ''
                        }
                        noData={formMode === 'edit'}
                        sx={{
                            width: '66%',
                        }}
                        disabled
                    />
                </Grid>

                <Grid item flex={1}>
                    <CustomDefaultInput
                        name="principalAmount"
                        label="Capital:"
                        value={
                            calculationResult
                                ? `$ ${toCurrency(calculationResult.principalAmount)}`
                                : ''
                        }
                        noData={formMode === 'edit'}
                        disabled
                    />
                </Grid>
                <Grid item flex={1}>
                    <CustomDefaultInput
                        name="disbursementFeeAmount"
                        label="Comisión:"
                        value={
                            calculationResult
                                ? `$ ${toCurrency(calculationResult.disbursementFeeAmount)}`
                                : ''
                        }
                        noData={formMode === 'edit'}
                        disabled
                    />
                </Grid>
                <Grid item flex={1}>
                    <CustomDefaultInput
                        name="vat"
                        label="Impuesto:"
                        value={calculationResult ? `$ ${toCurrency(calculationResult.vat)}` : ''}
                        noData={formMode === 'edit'}
                        disabled
                    />
                </Grid>
            </Grid>

            <Grid item container gap={3}>
                <Grid item container direction="column" gap={3} flex={1}>
                    <Grid item>
                        <CustomDefaultInput
                            name="projectedFinancingDays"
                            label="Duración del contrato:"
                            value={
                                calculationResult ? calculationResult.projectedFinancingDays : ''
                            }
                            noData={formMode === 'edit'}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <CustomDefaultInput
                            name="minGmv"
                            label="Ventas mínimas:"
                            value={
                                calculationResult ? `$ ${toCurrency(calculationResult.minGmv)}` : ''
                            }
                            noData={formMode === 'edit'}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        <CustomDefaultInput
                            name="maxDispositionValue"
                            label="Dispersión máxima mensual:"
                            value={
                                calculationResult
                                    ? `$ ${toCurrency(calculationResult.maxDispositionValue)}`
                                    : ''
                            }
                            noData={formMode === 'edit'}
                            disabled
                        />
                    </Grid>
                    <Grid item>
                        {payment?.paymentModel === 'revenue-based' ? (
                            <CustomDefaultInput
                                name="projectedFinancingPayments"
                                label="Días de pago:"
                                value={
                                    calculationResult
                                        ? calculationResult.projectedFinancingPayments
                                        : ''
                                }
                                noData={formMode === 'edit'}
                                disabled
                            />
                        ) : (
                            <CustomDefaultInput
                                name="minDailyPayment"
                                label="Pago diario:"
                                value={
                                    calculationResult
                                        ? `$ ${toCurrency(calculationResult.minDailyPayment)}`
                                        : ''
                                }
                                noData={formMode === 'edit'}
                                disabled
                            />
                        )}
                    </Grid>
                    <Grid item>
                        <CustomDefaultInput
                            name="projectedIrr"
                            label="TIR con I.V.A:"
                            value={
                                calculationResult
                                    ? `${toCurrency(calculationResult.projectedIrr * 100)}%`
                                    : ''
                            }
                            noData={formMode === 'edit'}
                            disabled
                        />
                    </Grid>
                    {payment?.paymentModel === 'revenue-based' && (
                        <>
                            <Grid item>
                                <CustomDefaultInput
                                    name="minIrr"
                                    label="Min TIR:"
                                    value={
                                        calculationResult
                                            ? `${toCurrency(calculationResult.minIrr * 100)}%`
                                            : ''
                                    }
                                    noData={formMode === 'edit'}
                                    disabled
                                />
                            </Grid>
                            <Grid item>
                                <CustomDefaultInput
                                    name="maxIrr"
                                    label="Max TIR:"
                                    value={
                                        calculationResult
                                            ? `${toCurrency(calculationResult.maxIrr * 100)}%`
                                            : ''
                                    }
                                    noData={formMode === 'edit'}
                                    disabled
                                />
                            </Grid>
                        </>
                    )}
                    <CustomDefaultInput
                        name="frequency"
                        label="Frecuencia de pago:"
                        value={calculationResult?.frequency}
                        noData={formMode === 'edit'}
                        disabled
                    />
                </Grid>
                <Grid item flex={1}>
                    <CustomDefaultInput
                        value={resComments}
                        onChange={(e) => setResComments(e.target.value)}
                        multiline
                        inputAlignment="left"
                        rows={26.9}
                        label="Comentarios:"
                        placeholder="Escribe aquí tus comentarios:"
                        disabled={formMode === 'edit'}
                        noData={formMode === 'edit'}
                        ignoreDisabledStyles
                    />
                </Grid>
            </Grid>
            <Grid item alignSelf="flex-end">
                <DefaultBtn size="small" disabled={formMode === 'edit'} onClick={onSave}>
                    Guardar
                </DefaultBtn>
            </Grid>
        </Grid>
    );
};

export default CalculationResult;
