import { VFC } from 'react';
import { Grid } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { WomanWithCalendarSvg, WomanWithDataSvg } from 'assets/SVGs';
import { Instructions, QuoteInstructionsProps } from './QuoteInstructions.types';

const INSTRUCTIONS: Instructions = {
    selectDate: {
        title: 'Establece la fecha estimada de firma de contrato y elige el modelo de pago del contrato.',
        img: <WomanWithCalendarSvg width="326px" height="298px" />,
    },
    calculate: {
        title: 'Ingresa los valores solicitados.',
        description: 'Al dar clic en calcular verás tu propuesta de financiamiento aquí.',
        img: <WomanWithDataSvg width="326px" height="298px" />,
    },
};

const QuoteInstructions: VFC<QuoteInstructionsProps> = ({ type }) => {
    return (
        <Grid item container xs={12} direction="column" alignItems="center" gap={2}>
            <Grid item width="326px" gap={2}>
                <Grid item>
                    <Typography variant="subtitle1" fontWeight={800} color="primary.dark">
                        {INSTRUCTIONS[type].title}
                    </Typography>
                </Grid>

                {INSTRUCTIONS[type].description && (
                    <Grid item mt={1}>
                        <Typography variant="subtitle2" color="primary">
                            {INSTRUCTIONS[type].description}
                        </Typography>
                    </Grid>
                )}
            </Grid>

            <Grid item>{INSTRUCTIONS[type].img}</Grid>
        </Grid>
    );
};

export default QuoteInstructions;
