import { styled } from '@mui/material';

export const SideBar = styled('div')(({ theme }) => ({
    width: '332px',
    backgroundColor: theme.palette.background.secondary,
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    padding: '25px 30px',
    position: 'fixed',
    marginLeft: -30,
    zIndex: 100,
}));

export const QuoteContainer = styled('div')(() => ({
    maxWidth: 1040,
    marginLeft: 395,
    boxSizing: 'border-box',
    flexDirection: 'column',
}));
