import { FC } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import Img from 'assets/img/404.png';
import { StyledImg } from './styles';
import { NotFoundProps } from './interfaces';

const NotFoundLayout: FC<NotFoundProps> = ({ message }) => {
    return (
        <Grid container justifyContent="center" alignItems="center" minHeight="100vh">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <StyledImg src={Img} alt="Not found" />
                <Typography variant="h3" color="primary.dark" fontWeight={700} mt="40px">
                    Oooops...
                </Typography>
                <Typography variant="body1" color="primary.dark" mt={1}>
                    {message ? message : 'La página solicitada no ha sido encontrada.'}
                </Typography>
            </Box>
        </Grid>
    );
};

export default NotFoundLayout;
