import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { hasActiveSession } from 'core/network';

export interface PrivatePageProps {
    /**
     * @default '/'
     */
    redirectTo?: string;
}

const PrivatePage: FC<PrivatePageProps> = ({ children, redirectTo = '/' }) =>
    hasActiveSession() ? <>{children}</> : <Navigate to={redirectTo} />;

export default PrivatePage;
