import { SessionState, Action } from './interfaces';

export const INITIAL_STATE: Readonly<SessionState> = {
    user: null,
    loading: true,
};

export const restoreSavedState = (initialState: SessionState) => {
    const selUser = sessionStorage.getItem('FPstorage-UserData'),
        effectiveState = { ...initialState };

    if (!initialState.user && selUser) {
        effectiveState.user = JSON.parse(selUser);
    }

    return effectiveState;
};

const reducer = (state: SessionState, { type, payload }: Action) => {
    switch (type) {
        case 'SET_USER':
            sessionStorage.setItem('FPstorage-UserData', JSON.stringify(payload));
            return {
                ...state,
                user: payload,
                loading: false,
            };
        case 'CLEAR_USER':
            sessionStorage.removeItem('FPstorage-UserData');
            return {
                ...INITIAL_STATE,
            };
        case 'SET_LOADING':
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};

export default reducer;
