import { FC } from 'react';
import { GlobalStyles } from '@mui/material';
import { FairplayThemeProvider } from '@fairplay2/ui';
import AvatarCompany from '@fairplay2/ui/graphics/AvatarCompanyEmpty.svg';
import AvatarUser from '@fairplay2/ui/graphics/AvatarUserEmpty.svg';
import { SessionProvider } from 'core/state';
import { Router } from 'core/router';
import { QuoteProvider } from 'modules/quotes/state';
import { DatePickerStyles } from '@fairplay2/ui-x-dates';

const App: FC = () => {
    return (
        <SessionProvider>
            <QuoteProvider>
                <GlobalStyles
                    styles={{
                        body: { backgroundColor: '#f8f8f8' },
                    }}
                />
                <DatePickerStyles />
                <FairplayThemeProvider
                    fallbackAvatars={{
                        company: AvatarCompany,
                        user: AvatarUser,
                    }}
                >
                    <Router />
                </FairplayThemeProvider>
            </QuoteProvider>
        </SessionProvider>
    );
};

export default App;
