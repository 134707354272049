import { VFC } from 'react';
import { Paper, Typography } from '@fairplay2/ui';
import { Grid } from '@mui/material';
import { ProjectedContractPayments } from 'modules/quotes/components';
import { useQuote } from 'modules/quotes/state';
import QuoteForm from '../quote-form/QuoteForm.component';
import QuoteInstructions from '../quote-instructions/QuoteInstructions.component';
import CalculationResult from '../calculation-result/CalculationResult.component';
import { Overlay } from './Quote.styles';
import { parseGraphData } from 'modules/quotes/utils';

const Quote: VFC = () => {
    const { payment, formMode, calculationResult, userInput } = useQuote();

    const parsedPaymentsGraphData = parseGraphData({
        // TODO: leave only <string, number> when backend fixes the response of graph
        contractPayments: calculationResult?.graph as Record<string, number | string>,
        upperBand: userInput?.upperBand as number,
        lowerBand: userInput?.lowerBand as number,
    });

    return (
        <Grid item container gap={3} alignItems="flex-start">
            <QuoteForm />
            <Grid item container flex={1}>
                {formMode === 'new' ? (
                    <QuoteInstructions
                        type={!!payment?.estimatedSignatureDate ? 'calculate' : 'selectDate'}
                    />
                ) : (
                    <CalculationResult />
                )}
            </Grid>
            {formMode !== 'new' && (
                <Grid item container xs={12} gap={3} direction="column" position="relative">
                    {formMode === 'edit' && <Overlay />}
                    <Typography
                        variant="subtitle1"
                        fontWeight={800}
                        color="primary.dark"
                        maxWidth="100%"
                    >
                        Gráfica de pagos
                    </Typography>

                    <Paper elevation={2} sx={{ padding: 3, bgcolor: 'white', maxWidth: '100%' }}>
                        <ProjectedContractPayments
                            detail={true}
                            paymentType={payment?.paymentModel}
                            graphData={
                                parsedPaymentsGraphData || {
                                    upperBand: [[0, 0]],
                                    lowerBand: [[0, 0]],
                                    payment: [[0, 0]],
                                }
                            }
                        />
                    </Paper>
                </Grid>
            )}
        </Grid>
    );
};

export default Quote;
