import { RawDashboardTopbar } from '@fairplay2/ui';
import { styled } from '@mui/material';
import { Env } from './interfaces';

export const TopContainer = styled('div')<{ env: Env }>(({ env }) => ({
    position: 'fixed',
    top: env === 'development' ? 29 : 0,
    width: '100%',
    zIndex: 99,
    display: 'flex',
    backgrounColor: 'green',
}));

export const MainContainer = styled('main')(() => ({
    flexGrow: 1,
    padding: '127px 30px 50px',
    position: 'relative',
}));

export const LeftTopBar = styled(RawDashboardTopbar)(({ theme }) => ({
    '&.MuiAppBar-root': {
        padding: '22px 0px 22px 30px',
        backgroundColor: theme.palette.primary.dark,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 50,
        left: 0,
        width: '360px',
        '@media (max-width: 1200px)': {
            width: '360px',
        },
        '@media (max-width: 1018px)': {
            display: 'none',
        },
    },
}));

export const RightTopBar = styled(RawDashboardTopbar)({
    '&.MuiAppBar-root': {
        width: 'calc(100% - 428px)',
        padding: '21px 30px',
        '@media (max-width: 1200px)': {
            width: 'calc(100% - 428px)',
        },
        '@media (max-width: 1018px)': {
            width: '100%',
        },
    },
});

export const EnvBanner = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
    fontWeight: 700,
    color: theme.palette.primary.dark,
    lineHeight: 1.1875,
    padding: 5,
    textAlign: 'center',
    position: 'fixed',
    width: '100%',
    zIndex: 98,
    top: 0,
}));
