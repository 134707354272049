import { styled, Card } from '@mui/material';
import { FullscreenLayout } from '@fairplay2/ui';
import { ReactComponent as ManWithDocuments } from 'assets/img/login/man_with_documents.svg';
import { ReactComponent as WomanWithChart } from 'assets/img/login/woman_with_chart.svg';
import { ReactComponent as VerticalLeaves } from 'assets/img/login/vertical_leaves.svg';
import Background from 'assets/img/login/login_bg.svg';

export const MainContainer = styled(FullscreenLayout)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.dark,
    overflowX: 'hidden',
    '&::before': {
        content: "' '",
        backgroundImage: `url(${Background})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: 761,
        height: 600,
        position: 'absolute',
        marginBottom: 60,
    },
}));

export const CardContainer = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.primary.dark,
    padding: '88px 0',
    margin: '30px 0',
    maxWidth: 561,
    position: 'relative',
    overflow: 'visible',
    width: '100%',
}));

export const RightDecoration = styled(ManWithDocuments)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('sm')]: {
        display: 'initial',
        position: 'absolute',
        bottom: -14,
        right: -67,
        width: 179,
    },
}));

export const LeftDecoration1 = styled(WomanWithChart)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('sm')]: {
        display: 'initial',
        position: 'absolute',
        zIndex: 30,
        bottom: -14,
        left: -49,
        width: 179,
    },
}));

export const LeftDecoration2 = styled(VerticalLeaves)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up('sm')]: {
        display: 'initial',
        position: 'absolute',
        zIndex: 29,
        bottom: 60,
        left: 5,
        width: 126,
        transform: 'translateX(-100%)',
    },
}));
