import { StrictMode } from 'react';
import { render } from 'react-dom';
import App from './App';

/* istanbul ignore next */
if ('REACT_APP_SENTRY_DSN' in process.env) {
    const Sentry = require('@sentry/react');
    const { Integrations } = require('@sentry/tracing');
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: process.env.REACT_APP_ENV_TYPE,
    });
}

render(
    <StrictMode>
        <App />
    </StrictMode>,
    document.getElementById('root'),
);
