/**
 * Performs local pagination of lists.
 *
 * @param fullList
 * @param page Page number (zero-based)
 * @param pageSize Page size (one-based)
 * @returns Sublist containing items at the specified page.
 */
export function getItemsAtPage<T>(fullList: T[], page: number, pageSize: number): T[] {
    return fullList.slice(page * pageSize, page * pageSize + pageSize);
}

export const paginationReducer = (
    state: { count: number; page: number; rowsPerPage: number },
    { type, payload }: { type: 'SET_COUNT' | 'SET_PAGE' | 'SET_ROWS_PER_PAGE'; payload: number },
) => {
    switch (type) {
        case 'SET_COUNT':
            return {
                ...state,
                count: payload,
                page: 0,
            };
        case 'SET_PAGE':
            return {
                ...state,
                page: payload,
            };
        case 'SET_ROWS_PER_PAGE':
            return {
                ...state,
                rowsPerPage: payload,
                page: 0,
            };
        default:
            return state;
    }
};
