import { Typography, DefaultBtn, Radio, InterpunctLoader, Select } from '@fairplay2/ui';
import { DatePicker } from '@fairplay2/ui-x-dates';
import { Alert, Collapse, FormControlLabel, Grid, MenuItem, RadioGroup } from '@mui/material';
import { DubitativeManSvg } from 'assets/SVGs';
import { FormItem } from 'core/components';
import { fairplayAPI } from 'core/network';
import { Form, useForm } from 'core/state';
import { isEmpty } from 'core/utils';
import { camelToSnakeObj, responseToCamelCase } from 'core/utils/formatting';
import { ConfirmationPromptModal } from 'modules/quotes/components';
import { QuoteUserInput, QuoteDic, QuoteCalculationResult, useQuote } from 'modules/quotes/state';
import { transformPercentages } from 'modules/quotes/utils';
import { ChangeEvent, useEffect, useState, VFC } from 'react';
import { StyledDatePickerWrapper } from './QuoteForm.styles';

const QUOTE_MODES: QuoteDic = {
        new: 'Calcular',
        calculated: 'Editar',
        edit: 'Recalcular',
    },
    productTypes = [
        { value: 'working-capital', name: 'Working Capital' },
        { value: 'sales-advancement', name: 'Sales Advancement' },
        { value: 'revolving', name: 'Revolving' },
        { value: 'factoring', name: 'Factoring' },
    ];

const QuoteForm: VFC = () => {
    const {
            hasErrors: formHasErrors,
            formData: { data: formData, ...formDataProps },
            dispatch: formDispatch,
        } = useForm<QuoteUserInput>(),
        {
            dispatch: quoteDispatch,
            payment,
            userInput,
            formMode,
            projectionSummary,
            todayDate,
        } = useQuote(),
        [confirmationPromptIsOpen, setConfirmationPromptIsOpen] = useState(false),
        [nextPaymentType, setNextPaymentType] = useState(''),
        [isLoading, setIsLoading] = useState(false),
        [alert, setAlert] = useState({
            isOpen: false,
            msg: '',
        });

    const switchConfirmationPrompt = () => {
        setConfirmationPromptIsOpen((prev) => !prev);

        if (nextPaymentType) setNextPaymentType('');
    };

    const onConfirmPaymentChange = () => {
        onClearInputs();
        quoteDispatch({
            type: 'SET_PAYMENT',
            payload: {
                paymentModel: nextPaymentType,
            },
        });
    };

    const onProductTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        quoteDispatch({
            type: 'SET_PAYMENT',
            payload: {
                productType: (event.target as HTMLInputElement).value,
            },
        });
    };

    const onPaymentTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!!payment?.estimatedSignatureDate || !!payment?.productType || !!userInput) {
            switchConfirmationPrompt();
            setNextPaymentType((event.target as HTMLInputElement).value);
        } else {
            quoteDispatch({
                type: 'SET_PAYMENT',
                payload: {
                    paymentModel: (event.target as HTMLInputElement).value,
                },
            });
        }
    };

    const onDateSelect = (date: Date | undefined) => {
        quoteDispatch({
            type: 'SET_PAYMENT',
            payload: {
                estimatedSignatureDate: date,
            },
        });
    };

    const onClearInputs = () => {
        setAlert((prev) => ({ ...prev, isOpen: false }));
        formDispatch({
            type: 'CLEAR_FORM_DATA',
        });

        quoteDispatch({ type: 'SET_INPUT_DATA', payload: null });
        quoteDispatch({ type: 'SET_CALCULATION_RESULT', payload: null });
        quoteDispatch({
            type: 'SET_PAYMENT',
            payload: {
                estimatedSignatureDate: undefined,
                paymentModel: 'revenue-based',
                productType: undefined,
            },
        });
        quoteDispatch({
            type: 'SET_MODE',
            payload: 'new',
        });
    };

    const onFormAction = () => {
        if (alert.isOpen) setAlert((prev) => ({ ...prev, isOpen: false }));
        if (formHasErrors) formDispatch({ type: 'SHOW_ERRORS', payload: true });

        if (!formHasErrors) {
            quoteDispatch({
                type: 'SET_INPUT_DATA',
                payload: formData,
            });

            if (formMode === 'new' || formMode === 'edit') {
                sendQuoteFormData();
            }
            if (formMode === 'calculated')
                quoteDispatch({
                    type: 'SET_MODE',
                    payload: 'edit',
                });
        }
    };

    const sendQuoteFormData = () => {
        setIsLoading(true);

        const formDataPayload: any = {
            /* eslint-disable camelcase */
            projection_id: projectionSummary?.projectionId,
            estimated_signature_date: `${payment?.estimatedSignatureDate?.getFullYear()}-${
                (payment?.estimatedSignatureDate?.getMonth() ?? 0) + 1
            }-${payment?.estimatedSignatureDate?.getDate()}`,
            product_type: payment?.productType,
            /* eslint-enable camelcase */
            ...camelToSnakeObj(
                transformPercentages(formData, [
                    'maxDispositionPercentage',
                    'disbursementFee',
                    'takeRate',
                ]),
            ),
        };

        fairplayAPI
            .post(`/api/v2/calculator-proposal/${payment?.paymentModel}`, formDataPayload, {
                baseService: 'CALCULATOR',
            })
            .then((res: any) => {
                quoteDispatch({
                    type: 'SET_CALCULATION_RESULT',
                    payload: responseToCamelCase(
                        res.data.body.results[0],
                    ) as QuoteCalculationResult,
                });

                quoteDispatch({
                    type: 'SET_MODE',
                    payload: 'calculated',
                });
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
            .catch((e) => {
                setAlert({
                    isOpen: true,
                    msg:
                        e.response?.data?.errors[0].error ||
                        'Se ha producido un error. Intente más tarde',
                });
                quoteDispatch({
                    type: 'SET_CALCULATION_RESULT',
                    payload: null,
                });
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        if (userInput)
            formDispatch({
                type: 'SET_FORM_DATA',
                payload: userInput,
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form
            form={{
                hasErrors: formHasErrors,
                formData: { data: formData, ...formDataProps },
                dispatch: formDispatch,
            }}
        >
            <ConfirmationPromptModal
                open={confirmationPromptIsOpen}
                onClose={switchConfirmationPrompt}
                title="¿Estas seguro? Se perderá la información"
                img={<DubitativeManSvg width="166px" height="180px" />}
                cancelTxt="Cancelar"
                confirmTxt="Sí, estoy seguro"
                onConfirm={onConfirmPaymentChange}
                sx={{ '.MuiDialogTitle-root': { textAlign: 'left' } }}
            >
                <Grid container textAlign="left">
                    <Grid item>
                        <Typography variant="subtitle2">
                            Si cambias la proyección los datos que has ingresado o seleccionado, se
                            perderán y deberás comenzar de nuevo.
                        </Typography>
                    </Grid>
                </Grid>
            </ConfirmationPromptModal>

            <Grid
                item
                container
                xs={5}
                padding="25px 30px"
                gap={3}
                direction="column"
                bgcolor="background.paper"
                borderRadius="20px"
                minWidth="365px"
                flexWrap="nowrap"
            >
                <Grid item>
                    <Select
                        labelVariant="external"
                        label="Selecciona el tipo de producto:"
                        disabled={!productTypes.length || formMode === 'calculated'}
                        value={payment?.productType}
                        onChange={onProductTypeChange}
                        ignoreDisabledStyles
                    >
                        <MenuItem value={undefined} disabled>
                            Mostrar todos
                        </MenuItem>
                        {productTypes.length > 0 &&
                            productTypes.map((option: any) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.name}
                                </MenuItem>
                            ))}
                    </Select>
                </Grid>
                <Grid item>
                    <StyledDatePickerWrapper>
                        <DatePicker
                            labelVariant="external"
                            label="Fecha estimada de firma del contrato:"
                            placeholder="Fecha de firma"
                            value={payment?.estimatedSignatureDate}
                            onChange={onDateSelect}
                            displayIcon
                            ignoreDisabledStyles
                            disabled={formMode === 'calculated'}
                            mode="single"
                            inputProps={{
                                autocomplete: 'off',
                            }}
                            PickerProps={{
                                fromDate: todayDate,
                            }}
                        />
                    </StyledDatePickerWrapper>
                </Grid>
                <Grid item>
                    <RadioGroup
                        value={payment?.paymentModel}
                        onChange={onPaymentTypeChange}
                        sx={{
                            flexDirection: 'row',
                        }}
                    >
                        <FormControlLabel
                            value="revenue-based"
                            control={<Radio color="primary" />}
                            label="Basado en ingresos"
                            disabled={formMode === 'calculated'}
                        />
                        <FormControlLabel
                            value="fixed-payments"
                            control={<Radio color="primary" />}
                            label="Pagos fijos"
                            disabled={formMode === 'calculated'}
                        />
                    </RadioGroup>
                </Grid>
                {!!payment?.estimatedSignatureDate && !!payment.productType && (
                    <>
                        <Grid item>
                            <Typography variant="subtitle2" fontWeight={800} color="primary.dark">
                                ¿Cuál será el monto de la inversión?
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FormItem
                                name="principalAmount"
                                label="Monto principal:"
                                inputType="number"
                                prefix="$"
                                disabled={formMode === 'calculated'}
                                ignoreDisabledStyles
                                validators={[isEmpty]}
                            />
                        </Grid>
                        <Grid item>
                            <FormItem
                                name="disbursementFee"
                                label="Comisión:"
                                inputType="number"
                                suffix="%"
                                disabled={formMode === 'calculated'}
                                ignoreDisabledStyles
                                validators={[isEmpty]}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2" fontWeight={800} color="primary.dark">
                                ¿Cómo se va a dispersar?
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FormItem
                                name="disbursementPeriodMonths"
                                label="Meses de dispersión:"
                                inputType="number"
                                disabled={formMode === 'calculated'}
                                ignoreDisabledStyles
                                validators={[isEmpty]}
                            />
                        </Grid>
                        <Grid item>
                            <FormItem
                                name="maxDispositionPercentage"
                                label="Dispersión máxima mensual:"
                                inputType="number"
                                suffix="%"
                                disabled={formMode === 'calculated'}
                                ignoreDisabledStyles
                                validators={[isEmpty]}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="subtitle2" fontWeight={800} color="primary.dark">
                                ¿Cómo se va a pagar?
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FormItem
                                name="gracePeriodDays"
                                label="Días de gracia:"
                                inputType="number"
                                disabled={formMode === 'calculated'}
                                ignoreDisabledStyles
                                validators={[isEmpty]}
                            />
                        </Grid>
                        {payment?.paymentModel === 'revenue-based' ? (
                            <>
                                <Grid item>
                                    <FormItem
                                        name="lowerBand"
                                        label="Pago mínimo:"
                                        inputType="number"
                                        prefix="$"
                                        disabled={formMode === 'calculated'}
                                        ignoreDisabledStyles
                                        validators={[isEmpty]}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormItem
                                        name="upperBand"
                                        label="Pago máximo:"
                                        inputType="number"
                                        prefix="$"
                                        disabled={formMode === 'calculated'}
                                        ignoreDisabledStyles
                                        validators={[isEmpty]}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormItem
                                        name="takeRate"
                                        label="Factor de reembolso:"
                                        inputType="number"
                                        suffix="%"
                                        disabled={formMode === 'calculated'}
                                        ignoreDisabledStyles
                                        validators={[isEmpty]}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item>
                                    <FormItem
                                        name="projectedFinancingDays"
                                        label="Días para pagar:"
                                        inputType="number"
                                        disabled={formMode === 'calculated'}
                                        ignoreDisabledStyles
                                        validators={[isEmpty]}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item>
                            <FormItem
                                name="frequency"
                                label="Frecuencia de pago:"
                                inputType="number"
                                disabled={formMode === 'calculated'}
                                ignoreDisabledStyles
                                validators={[isEmpty]}
                            />
                        </Grid>
                        <Grid item container justifyContent="flex-end">
                            <Collapse in={!!alert.isOpen}>
                                <Alert severity="error" aria-label="Error en detalle de proyección">
                                    {alert.msg}
                                </Alert>
                            </Collapse>
                        </Grid>

                        <Grid
                            item
                            container
                            justifyContent="flex-end"
                            gap={2}
                            mt={!!alert.isOpen ? 0 : 1}
                        >
                            {isLoading ? (
                                <InterpunctLoader />
                            ) : (
                                <>
                                    <Grid item>
                                        <DefaultBtn
                                            onClick={onClearInputs}
                                            size="small"
                                            variant="outlined"
                                            sx={{ backgroundColor: 'white' }}
                                        >
                                            Limpiar campos
                                        </DefaultBtn>
                                    </Grid>
                                    <Grid item>
                                        <DefaultBtn onClick={onFormAction} size="small">
                                            {QUOTE_MODES[formMode]}
                                        </DefaultBtn>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
        </Form>
    );
};

export default QuoteForm;
