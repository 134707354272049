import { FC } from 'react';
import { Grid } from '@mui/material';
import { Chart } from 'core/components';
import { ProjectedContractPaymentsProps } from '../../interfaces';

const CHART_TOOLTIP = { valueDecimals: 2, valuePrefix: '$', shared: true };

const ProjectedContractPayments: FC<ProjectedContractPaymentsProps> = ({
    graphData: { lowerBand, payment, upperBand },
    detail,
    paymentType = 'revenue-based',
}) => {
    const series: Array<Highcharts.SeriesOptionsType> = [
        {
            type: 'line',
            color: '#9689F2',
            lineWidth: 2,
            name: 'Pago',
            data: payment,
            marker: {
                enabled: true,
                radius: 3,
            },
        },
        {
            type: 'line',
            color: '#18ADE3',
            lineWidth: 2,
            name: 'Pago máximo',
            data: upperBand,
            marker: {
                enabled: false,
                radius: 0,
            },
        },
        {
            type: 'line',
            color: '#90ED7D',
            lineWidth: 2,
            name: 'Pago mínimo',
            data: lowerBand,
            marker: {
                enabled: false,
            },
        },
    ];

    return (
        <Grid item xs={12}>
            {!detail && <h5 className="primary-color mb-xs">Gráfica de pagos</h5>}
            <Chart
                tooltip={CHART_TOOLTIP}
                series={paymentType === 'revenue-based' ? series : [series[0]]}
            />
        </Grid>
    );
};

export default ProjectedContractPayments;
