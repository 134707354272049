import { Datapoints } from 'core/interfaces';
import { Graph, ContractPaymentsGraphData } from './interfaces';

export const parseGraphData = (dataArr: Graph | null) => {
    const lowerBand: Datapoints = [];
    const payment: Datapoints = [];
    const upperBand: Datapoints = [];

    if (dataArr?.contractPayments) {
        for (const date in dataArr.contractPayments) {
            const parsedDate = Date.parse(date.split('T')[0]),
                // TODO: remove convertion when backend fixes the response of graph
                paymentAmount = dataArr.contractPayments[date],
                graphPoint =
                    typeof paymentAmount === 'string' ? Number(paymentAmount) : paymentAmount;

            payment.push([parsedDate, graphPoint]);
            upperBand.push([parsedDate, dataArr.upperBand]);
            lowerBand.push([parsedDate, dataArr.lowerBand]);
        }
    }

    const resultGraph: ContractPaymentsGraphData = {
        lowerBand,
        payment,
        upperBand,
    };

    return resultGraph;
};

export const transformPercentages = <T extends Record<keyof T, number>>(
    data: T,
    fields: Array<keyof T>,
) => {
    return Object.entries(data).reduce((acc, [key, val]) => {
        if (fields.includes(key as keyof T)) acc[key] = (val as number) / 100;
        else acc[key] = val;

        return acc;
    }, {} as any) as T;
};
