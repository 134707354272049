import { useReducer, ReactNode } from 'react';
import { createContextWithHook } from 'core/hooks';
import { noop } from 'core/utils';
import { FormContextType } from './interfaces';
import { INITIAL_STATE, formReducer } from './FormReducer';

const [FormContext, useFormContext] = createContextWithHook<FormContextType<any>>(
    {
        formData: { data: {}, errors: {}, showErrors: false },
        hasErrors: false,
        dispatch: noop,
    },
    'useForm must be used within a FormContext',
);

const useForm = <T,>(): FormContextType<T> => {
    const [{ hasErrors, ...formData }, dispatch] = useReducer(formReducer, INITIAL_STATE);

    return {
        formData,
        hasErrors,
        dispatch,
    } as FormContextType<T>;
};

export const Form = <T,>({ children, form }: { children: ReactNode; form: FormContextType<T> }) => {
    return <FormContext.Provider value={form}>{children}</FormContext.Provider>;
};

export { FormContext, useForm, useFormContext };

export default Form;
