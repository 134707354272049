import { styled } from '@mui/system';

export const Overlay = styled('div')(() => ({
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    opacity: 0.7,
    zIndex: 10,
}));
