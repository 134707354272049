import { VFC, useState, useRef, SyntheticEvent, FocusEventHandler, FormEventHandler } from 'react';
import { Alert, Collapse, Grid, Typography } from '@mui/material';
import { DefaultBtn, DefaultInput, InterpunctLoader, TabGroup } from '@fairplay2/ui';
import { fairplayAPI } from 'core/network';
import { isValidRfc, responseToCamelCase } from 'core/utils';
import { FormErrors } from 'core/interfaces';
import { DubitativeManSvg } from 'assets/SVGs';
import { CompanyData, ConfirmationPromptModal } from '../components';
import { Company, useQuote } from '../state';
import { ProjectionStep, Quote, Step1Instructions } from './components';
import { QuoteContainer, SideBar } from './styles';
import { FindCompanyForm } from './interfaces';

const DEFAULT_ERRORS: FormErrors<FindCompanyForm, '_form'> = {
    rfc: '',
    _form: '',
};

const hasErrors = (errorsDic: typeof DEFAULT_ERRORS) =>
    Object.values(errorsDic).some((error) => !!error);

const NewQuote: VFC = () => {
    const { projectionSummary, company, userStep, dispatch, formMode } = useQuote(),
        [loading, setLoading] = useState(false),
        [confirmationPromptIsOpen, setConfirmationPromptIsOpen] = useState(false),
        [errors, setErrors] = useState(DEFAULT_ERRORS);

    const { current: onTabChange } = useRef((e: SyntheticEvent, newValue: number) =>
        dispatch({ type: 'SET_USER_STEP', payload: newValue }),
    );

    const getRfcError = (rfc: string) => {
        let error = '';

        if (!rfc) error = 'Introduce el RFC';
        else if (!isValidRfc(rfc)) error = 'El RFC no tiene un formato válido';

        return error;
    };

    const onInputBlur: FocusEventHandler<HTMLInputElement> = (e) => {
        setErrors({ _form: DEFAULT_ERRORS._form, rfc: getRfcError(e.target.value) });
    };

    const onRfcConfirm: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault();

        const rfcInput = (e.target as HTMLFormElement).elements[
            'company-rfc' as any
        ] as HTMLInputElement;

        const rfcError = getRfcError(rfcInput.value);
        if (rfcError) {
            setErrors({ _form: DEFAULT_ERRORS._form, rfc: rfcError });
            return;
        }

        setLoading(true);
        setErrors((prev) => (hasErrors(prev) ? DEFAULT_ERRORS : prev));

        fairplayAPI
            .get(`/api/v2/company?company_rfc=${rfcInput.value.toUpperCase()}`, {
                baseService: 'CALCULATOR',
            })
            .then((res: any) => {
                if (res.data.body.results.length > 1) throw new Error('Escribe el RFC completo');
                dispatch({
                    type: 'SET_COMPANY',
                    payload: responseToCamelCase(
                        res.data.body.results[0] as Record<string, any>,
                    ) as Company,
                });
            })
            .catch((e) => {
                setErrors((prev) => ({
                    ...prev,
                    _form:
                        e.response?.data?.errors[0]?.error ||
                        'Se ha producido un error. Intente más tarde',
                }));
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onConfirmClientChange = () => {
        dispatch({ type: 'CLEAR_QUOTE' });
    };

    const onClientChange = () => {
        if (formMode === 'calculated') {
            setConfirmationPromptIsOpen(true);
        } else onConfirmClientChange();
    };

    return (
        <>
            <ConfirmationPromptModal
                open={confirmationPromptIsOpen}
                onClose={() => setConfirmationPromptIsOpen(false)}
                title="¿Estas seguro? Se perderá la información"
                img={<DubitativeManSvg width="166px" height="180px" />}
                cancelTxt="Cancelar"
                confirmTxt="Sí, estoy seguro"
                onConfirm={onConfirmClientChange}
                sx={{ '.MuiDialogTitle-root': { textAlign: 'left' } }}
            >
                <Grid container textAlign="left">
                    <Grid item>
                        <Typography variant="subtitle2">
                            Si cambias de cliente los datos que has ingresado o seleccionado, se
                            perderán y deberás comenzar de nuevo.
                        </Typography>
                    </Grid>
                </Grid>
            </ConfirmationPromptModal>
            <SideBar>
                {!company ? (
                    <Grid
                        container
                        justifyContent="right"
                        component="form"
                        onSubmit={onRfcConfirm}
                        noValidate
                    >
                        <Typography
                            color="primary.main"
                            lineHeight={1.125}
                            textAlign="left"
                            width="100%"
                        >
                            Crea una nueva propuesta
                        </Typography>
                        <DefaultInput
                            name="company-rfc"
                            type="text"
                            label="Ingresa el RFC:"
                            labelVariant="external"
                            defaultValue=""
                            onBlur={onInputBlur}
                            disabled={loading}
                            error={errors.rfc}
                            sx={{ my: 3 }}
                            inputProps={{
                                style: { textTransform: 'uppercase' },
                                maxLength: 13,
                            }}
                        />
                        <Grid item xs={12}>
                            <Collapse in={!!errors._form}>
                                <Alert severity="error" sx={{ mb: 3 }}>
                                    {errors._form}
                                </Alert>
                            </Collapse>
                        </Grid>
                        {loading ? (
                            <InterpunctLoader data-testid="interpunct-loader-new-quote" />
                        ) : (
                            <DefaultBtn size="small" type="submit" sx={{ minWidth: 'initial' }}>
                                Confirmar
                            </DefaultBtn>
                        )}
                    </Grid>
                ) : (
                    <>
                        <DefaultBtn variant="text" sx={{ p: 0, mb: 3.5 }} onClick={onClientChange}>
                            Cambiar cliente
                        </DefaultBtn>
                        <CompanyData
                            company={company as Company}
                            projection={projectionSummary || undefined}
                        />
                    </>
                )}
            </SideBar>
            <QuoteContainer>
                <TabGroup
                    value={userStep}
                    onChange={onTabChange}
                    tabs={[
                        {
                            label: 'Proyección',
                            disabled: !company,
                        },
                        {
                            label: 'Propuesta',
                            disabled: !company || !projectionSummary,
                        },
                    ]}
                    indicatorStyles={{ expand: true }}
                    sx={{ '.MuiTabs-root': { mb: 3 } }}
                >
                    <ProjectionStep />
                    <Quote />
                </TabGroup>
                {!company && <Step1Instructions justifyContent="center" mt="100px" />}
            </QuoteContainer>
        </>
    );
};

export default NewQuote;
