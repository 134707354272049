import { DefaultBtn, InterpunctLoader, Modal, Typography } from '@fairplay2/ui';
import { DialogTitle, Grid } from '@mui/material';
import { VFC } from 'react';
import { ConfirmationPromptModalProps } from './ConfirmationPromptModal.types';

const ConfirmationPromptModal: VFC<ConfirmationPromptModalProps> = ({
    open,
    onClose,
    onConfirm,
    onCancel,
    confirmTxt = 'Confirmar',
    cancelTxt = 'Cancelar',
    title,
    img,
    children,
    isLoading,
    onlyConfirmBtn,
    closeOnAction = {
        onCancel: true,
        onConfirm: true,
    },
    ...props
}) => {
    const closeOnActionBtn = (type: 'confirm' | 'cancel') => {
        if (type === 'confirm') {
            onConfirm?.();
            if (closeOnAction.onConfirm) onClose?.();
        } else {
            onCancel?.();
            if (closeOnAction.onCancel || !onCancel) onClose?.();
        }
    };

    return (
        <Modal
            disableBackdropClick
            open={open}
            onClose={onClose}
            maxWidth="sm"
            closeIconButton={false}
            {...props}
        >
            <Grid container gap={2} direction="column">
                <Grid item alignSelf="center">
                    {img}
                </Grid>
                <Grid item textAlign="center">
                    <Typography
                        variant="subtitle1"
                        color="primary.dark"
                        fontWeight={800}
                        component={DialogTitle}
                        padding={0}
                        lineHeight={1.375}
                    >
                        {title}
                    </Typography>
                </Grid>
                {!!children && (
                    <Grid item textAlign="center">
                        {children}
                    </Grid>
                )}

                <Grid item container justifyContent="center" gap={2} mt={2}>
                    {isLoading ? (
                        <InterpunctLoader />
                    ) : (
                        <>
                            {!onlyConfirmBtn && (
                                <Grid item>
                                    <DefaultBtn
                                        onClick={() => closeOnActionBtn('cancel')}
                                        size="small"
                                        variant="outlined"
                                    >
                                        {cancelTxt}
                                    </DefaultBtn>
                                </Grid>
                            )}
                            <Grid item>
                                <DefaultBtn
                                    onClick={() => closeOnActionBtn('confirm')}
                                    size="small"
                                >
                                    {confirmTxt}
                                </DefaultBtn>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ConfirmationPromptModal;
