import { Reducer } from 'react';
import { QuoteStateAction, QuoteState, ProjectionDataPoints, Payment } from './interfaces';

export const INITIAL_STATE: QuoteState = {
    formMode: 'new',
    company: null,
    latestProjections: null,
    projectionSummary: null,
    projectionDataPoints: {
        gmv: null,
        seasonality: null,
        trend: null,
    },
    payment: {
        estimatedSignatureDate: undefined,
        paymentModel: 'revenue-based',
        productType: undefined,
    },
    userInput: null,
    calculationResult: null,
    userStep: false,
    todayDate: new Date(),
};

export const restoreSavedState = (initialState: QuoteState) => {
    const company = sessionStorage.getItem('FPstorage-Quote-Company'),
        projections = sessionStorage.getItem('FPstorage-Quote-ProjectionsList'),
        projectionSummary = sessionStorage.getItem('FPstorage-Quote-ProjectionSummary'),
        projectionDataPoints = sessionStorage.getItem('FPstorage-Quote-ProjectionDataPoints'),
        userStep = sessionStorage.getItem('FPstorage-Quote-Step'),
        payment = sessionStorage.getItem('FPstorage-Quote-Payment'),
        userInput = sessionStorage.getItem('FPstorage-Quote-UserInput'),
        calculationResult = sessionStorage.getItem('FPstorage-Quote-CalculationResult'),
        formMode = sessionStorage.getItem('FPstorage-Quote-Mode'),
        todayDate = sessionStorage.getItem('FPstorage-Quote-TodayDate'),
        effectiveState = { ...initialState };

    if (company) effectiveState.company = JSON.parse(company);

    if (projections) effectiveState.latestProjections = JSON.parse(projections);

    if (projectionSummary) effectiveState.projectionSummary = JSON.parse(projectionSummary);

    if (projectionDataPoints)
        effectiveState.projectionDataPoints = JSON.parse(projectionDataPoints);

    if (userStep !== null) effectiveState.userStep = JSON.parse(userStep);

    if (payment) {
        const parsedPayment = JSON.parse(payment);
        effectiveState.payment = {
            ...parsedPayment,
            estimatedSignatureDate: parsedPayment.estimatedSignatureDate
                ? new Date(parsedPayment.estimatedSignatureDate)
                : undefined,
        };
    }

    if (userInput) effectiveState.userInput = JSON.parse(userInput);

    if (calculationResult) effectiveState.calculationResult = JSON.parse(calculationResult);

    if (formMode) effectiveState.formMode = JSON.parse(formMode);

    if (todayDate) effectiveState.todayDate = new Date(todayDate);

    return effectiveState;
};

export const quoteReducer: Reducer<QuoteState, QuoteStateAction> = (state, { type, payload }) => {
    switch (type) {
        case 'CLEAR_QUOTE':
            sessionStorage.removeItem('FPstorage-Quote-Company');
            sessionStorage.removeItem('FPstorage-Quote-ProjectionsList');
            sessionStorage.removeItem('FPstorage-Quote-ProjectionSummary');
            sessionStorage.removeItem('FPstorage-Quote-ProjectionDataPoints');
            sessionStorage.removeItem('FPstorage-Quote-Step');
            sessionStorage.removeItem('FPstorage-Quote-Payment');
            sessionStorage.removeItem('FPstorage-Quote-UserInput');
            sessionStorage.removeItem('FPstorage-Quote-CalculationResult');
            sessionStorage.removeItem('FPstorage-Quote-Mode');

            return INITIAL_STATE;
        case 'SET_USER_STEP':
            sessionStorage.setItem('FPstorage-Quote-Step', JSON.stringify(payload));

            return { ...state, userStep: payload } as QuoteState;
        case 'SET_COMPANY':
            const userStep = payload ? 0 : false;

            sessionStorage.setItem('FPstorage-Quote-Company', JSON.stringify(payload));
            sessionStorage.removeItem('FPstorage-Quote-ProjectionsList');
            sessionStorage.removeItem('FPstorage-Quote-ProjectionSummary');
            sessionStorage.removeItem('FPstorage-Quote-ProjectionDataPoints');
            sessionStorage.setItem('FPstorage-Quote-Step', JSON.stringify(userStep));

            return {
                ...state,
                company: payload,
                latestProjections: null,
                projectionSummary: null,
                projectionDataPoints: INITIAL_STATE.projectionDataPoints,
                userStep,
            } as QuoteState;
        case 'SET_LATEST_PROJECTIONS':
            const payloadWithLowerCaseSourceId = payload
                ? payload.map((projection) => ({
                      ...projection,
                      sourceId: projection.sourceId.toLowerCase(),
                  }))
                : payload;

            sessionStorage.setItem(
                'FPstorage-Quote-ProjectionsList',
                JSON.stringify(payloadWithLowerCaseSourceId),
            );

            return {
                ...state,
                latestProjections: payloadWithLowerCaseSourceId,
            } as QuoteState;
        case 'SET_PROJECTION_SUMMARY':
            sessionStorage.setItem('FPstorage-Quote-ProjectionSummary', JSON.stringify(payload));
            if (!payload) sessionStorage.removeItem('FPstorage-Quote-ProjectionDataPoints');

            return {
                ...state,
                projectionSummary: payload,
                projectionDataPoints: payload
                    ? state.projectionDataPoints
                    : INITIAL_STATE.projectionDataPoints,
            } as QuoteState;
        case 'SET_PROJECTION_DATAPOINTS':
            // While vscode seems to understand payload's dynamic typing,
            //  CRA is not and throws false negatives
            const _payload = payload as Partial<ProjectionDataPoints>;
            let projectionDataPoints: ProjectionDataPoints = { ...state.projectionDataPoints };

            if (_payload.gmv !== undefined) projectionDataPoints.gmv = _payload.gmv;
            if (_payload.trend !== undefined) projectionDataPoints.trend = _payload.trend;
            if (_payload.seasonality !== undefined)
                projectionDataPoints.seasonality = _payload.seasonality;

            sessionStorage.setItem(
                'FPstorage-Quote-ProjectionDataPoints',
                JSON.stringify(projectionDataPoints),
            );

            return {
                ...state,
                projectionDataPoints,
            } as QuoteState;
        case 'SET_PAYMENT':
            sessionStorage.setItem(
                'FPstorage-Quote-Payment',
                JSON.stringify({ ...state.payment, ...(payload as Payment) }),
            );
            return {
                ...state,
                payment: { ...state.payment, ...(payload as Payment) },
            } as QuoteState;
        case 'SET_INPUT_DATA':
            sessionStorage.setItem('FPstorage-Quote-UserInput', JSON.stringify(payload));
            return {
                ...state,
                userInput: payload,
            } as QuoteState;
        case 'SET_CALCULATION_RESULT':
            sessionStorage.setItem('FPstorage-Quote-CalculationResult', JSON.stringify(payload));
            return {
                ...state,
                calculationResult: payload,
            } as QuoteState;
        case 'SET_MODE':
            sessionStorage.setItem('FPstorage-Quote-Mode', JSON.stringify(payload));
            return {
                ...state,
                formMode: payload,
            } as QuoteState;
        default:
            return state;
    }
};
