import { Grid } from '@mui/material';
import { VFC } from 'react';
import { Divider, Typography, FairplayLogoGraphic } from '@fairplay2/ui';

const Banner: VFC = () => {
    return (
        <Grid container gap={2}>
            <Grid item container alignItems="center" xs={5}>
                <FairplayLogoGraphic width="130px" height="40px" />
            </Grid>
            <Grid item paddingY="6px">
                <Divider orientation="vertical" color="green" />
            </Grid>
            <Grid item xs={5} display="flex" alignItems="center">
                <Typography variant="subtitle2" fontWeight={800} lineHeight={1.125}>
                    Calculadora Financiera
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Banner;
