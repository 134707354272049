import { FC, ChangeEvent, FormEvent, useState, useEffect, useRef, FocusEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { Box, CardContent, Grid, Collapse, Alert, Typography, Divider } from '@mui/material';
import {
    DefaultInput,
    PasswordInput,
    FairplayLogoGraphic,
    DefaultBtn,
    InterpunctLoader,
} from '@fairplay2/ui';
import { MailOutline } from '@mui/icons-material';
import { FormErrors } from 'core/interfaces';
import { useSession } from 'core/state';
import { endSession } from 'core/network';
import { isValidEmail } from 'core/utils';
import {
    MainContainer,
    CardContainer,
    RightDecoration,
    LeftDecoration1,
    LeftDecoration2,
} from './styles';
import { LogInForm } from './interfaces';

const DEFAULT_FORM_VALUES: LogInForm = {
        email: '',
        password: '',
    },
    DEFAULT_FORM_ERRORS: FormErrors<LogInForm> = DEFAULT_FORM_VALUES,
    EMPTY_FORM_ERRORS: FormErrors<LogInForm> = {
        password: 'Ingresa la contraseña',
        email: 'Ingresa un email',
    },
    INVALID_EMAIL_ERROR = 'Email no válido';

const LogIn: FC = () => {
    const { login } = useSession(),
        navigate = useNavigate(),
        recaptchaRef = useRef<null | ReCAPTCHA>(null),
        [loading, setLoading] = useState(false),
        [values, setValues] = useState(DEFAULT_FORM_VALUES),
        [errors, setErrors] = useState(DEFAULT_FORM_ERRORS),
        [alert, setAlert] = useState({
            text: '',
            open: false,
        });

    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        const recaptchaValue = recaptchaRef.current?.getValue();
        e.preventDefault();

        closeErrorAlert();

        if (recaptchaValue) {
            setLoading(true);
            const data = {
                /* eslint-disable camelcase */
                email: values.email,
                password: values.password,
                g_recaptcha_response: recaptchaValue,
                /* eslint-enable camelcase */
            };
            login(data)
                .then(() => {
                    clearForm();
                    navigate('/app/calculadora');
                })
                .catch((err: any) => {
                    if (err.response)
                        if (err.response.data.errors.non_field_errors)
                            showErrorAlert(err.response.data.errors.non_field_errors);
                        else if (err.response.data.errors.detail)
                            showErrorAlert(err.response.data.errors.detail);
                        else showErrorAlert('Se ha producido un error al iniciar la sesión.');
                    else showErrorAlert('Se ha producido un error. Intente más tarde');
                    setTimeout(function () {
                        closeErrorAlert();
                    }, 5000);
                })
                .finally(() => {
                    setLoading(false);
                    recaptchaRef.current?.reset();
                });
        } else if (Object.values(errors).every((error) => error === ''))
            showErrorAlert('Es necesario completar el reCAPTCHA');
    };

    const onInputBlur = (e: FocusEvent<HTMLInputElement>) => {
        const inputName = e.target.name as keyof typeof EMPTY_FORM_ERRORS;

        let error = !values[inputName] ? EMPTY_FORM_ERRORS[inputName] : '';
        if (!error && inputName === 'email')
            error = isValidEmail(values.email) ? error : INVALID_EMAIL_ERROR;

        setErrors((prev) => ({ ...prev, [inputName]: error }));
    };

    const closeErrorAlert = () => setAlert({ text: '', open: false });

    const showErrorAlert = (error: string) => setAlert({ text: error, open: true });

    const clearForm = () => {
        setValues(DEFAULT_FORM_VALUES);
        setErrors(DEFAULT_FORM_ERRORS);
    };

    useEffect(() => {
        endSession();
    }, []);

    return (
        <MainContainer>
            <CardContainer>
                <Box display="flex" width="100%" justifyContent="center" alignItems="center">
                    <FairplayLogoGraphic height={48} aria-hidden="true" />
                    <Divider orientation="vertical" sx={{ height: 49, width: '1px', mx: 1.5 }} />
                    <Typography
                        color="white"
                        fontWeight={700}
                        width={106}
                        textAlign="left"
                        fontSize={17}
                    >
                        Calculadora Financiera
                    </Typography>
                </Box>
                <CardContent sx={{ pb: '0 !important', pt: 4.5 }}>
                    <Grid
                        container
                        direction="column"
                        component="form"
                        id="login"
                        onSubmit={handleSubmit}
                        noValidate
                        maxWidth={300}
                        marginX="auto"
                    >
                        <Grid item mb={3}>
                            <DefaultInput
                                name="email"
                                placeholder="Email"
                                label="Email"
                                type="email"
                                required
                                forceLegibility
                                disabled={loading}
                                value={values.email}
                                onChange={onInputChange}
                                onBlur={onInputBlur}
                                error={errors.email}
                                startAdornment={<MailOutline />}
                            />
                        </Grid>
                        <Grid item mb={3}>
                            <PasswordInput
                                placeholder="Contraseña"
                                name="password"
                                label="Contraseña"
                                value={values.password}
                                required
                                forceHiddenText={loading}
                                forceLegibility
                                disabled={loading}
                                onChange={onInputChange}
                                onBlur={onInputBlur}
                                error={errors.password}
                            />
                        </Grid>
                        <Grid
                            item
                            marginBottom={alert.open ? undefined : 3}
                            sx={{
                                transform: {
                                    lg: 'scale(0.9)',
                                    xl: 'scale(1)',
                                },
                            }}
                        >
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={process.env.REACT_APP_GOOLE_RECAPTCHA_SITE_KEY as string}
                            />
                        </Grid>
                        <Grid item my={alert.open ? 3 : undefined}>
                            <Collapse in={alert.open}>
                                <Alert severity="error">{alert.text}</Alert>
                            </Collapse>
                        </Grid>

                        <Grid container justifyContent="center">
                            {loading ? (
                                <InterpunctLoader sx={{ my: 1.5 }} />
                            ) : (
                                <DefaultBtn color="mixed" type="submit" fullWidth>
                                    Iniciar sesión
                                </DefaultBtn>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
                <LeftDecoration1 aria-hidden="true" />
                <LeftDecoration2 aria-hidden="true" />
                <RightDecoration aria-hidden="true" />
            </CardContainer>
        </MainContainer>
    );
};

export default LogIn;
