import { createContext, useContext, Context } from 'react';

const createContextWithHook = <T>(defaultValue: T, errorMessage: string) => {
    const CreatedContext = createContext<T>(defaultValue);

    const useContextWithError = () => {
        const context = useContext(CreatedContext);
        if (context === null) {
            throw new Error(errorMessage);
        }
        return context;
    };

    return [CreatedContext, useContextWithError] as [Context<T>, () => T];
};

export default createContextWithHook;
