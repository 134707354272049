import { FC } from 'react';
import { LeftTopBar, MainContainer, RightTopBar, TopContainer, EnvBanner } from './styles';
import { ProfileData, Divider, DefaultBtn } from '@fairplay2/ui';
import { Grid } from '@mui/material';
import { Banner } from 'core/components';
import { useSession } from 'core/state';
import { useNavigate } from 'react-router-dom';
import { Env } from './interfaces';

const AppLayout: FC = ({ children }) => {
    const { logout: sessionLogout, user } = useSession(),
        navigate = useNavigate(),
        env: Env = process.env.REACT_APP_ENV_TYPE as Env;

    const logout = () => {
        sessionLogout();
        navigate('/');
    };

    return (
        <>
            {env === 'development' && (
                <EnvBanner role="alert">
                    Te encuentras en: <span style={{ textTransform: 'uppercase' }}>{env}</span>
                </EnvBanner>
            )}
            <TopContainer env={env}>
                <LeftTopBar>
                    <Banner />
                </LeftTopBar>
                <RightTopBar>
                    <Grid container justifyContent="flex-end" gap={4}>
                        <Grid item>
                            <ProfileData
                                fallbackTo="user"
                                size="xs"
                                src=""
                                name={user ? `${user.firstName} ${user.lastName}` : undefined}
                                legend="Administrador"
                                variant="mono"
                            />
                        </Grid>
                        <Grid item>
                            <Divider orientation="vertical" color="green" />
                        </Grid>
                        <Grid item>
                            <DefaultBtn size="small" onClick={logout}>
                                Cerrar sesión
                            </DefaultBtn>
                        </Grid>
                    </Grid>
                </RightTopBar>
            </TopContainer>
            <MainContainer>{children}</MainContainer>
        </>
    );
};

export default AppLayout;
