import { VFC } from 'react';
import { Grid, GridProps } from '@mui/material';
import { Typography } from '@fairplay2/ui';
import { ReactComponent as ManFacingFiles } from 'assets/img/man_facing_files.svg';

const Step1Instructions: VFC<Omit<GridProps, 'container' | 'alignItems'>> = (props) => (
    <Grid container alignItems="center" {...props}>
        <Grid item maxWidth={320}>
            <Typography variant="subtitle1" color="primary.dark" fontWeight={700} lineHeight={1.44}>
                ¿Necesitas una nueva propuesta de financiamiento?
            </Typography>
            <Typography variant="subtitle1" color="primary.main" lineHeight={1.44} mt={2}>
                Ingresa el RFC completo de la empresa para comenzar.
            </Typography>
        </Grid>
        <Grid ml={5}>
            <ManFacingFiles width={326} aria-hidden="true" />
        </Grid>
    </Grid>
);

export default Step1Instructions;
